import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    team: null,
    leaderboard: [],
    club: [],
    event: {},
    message: null,
    error: null,
    users: []
}

const TeamSlice = createSlice({
    name: 'Teams',
    initialState,
    reducers: {
        getTeamListStart: (state) => {
            state.isLoading = true;
        },
        getTeamListSuccess: (state, action) => {
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false
        },
        getTeamListFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },

        // Create Team Slice
        createTeamStart: (state) => {
            state.isLoading = true;
        },
        createTeamSuccess: (state, action) => {
            state.team = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        createTeamFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },

        // Get Team By Id
        getTeamByIdStart: (state) => {
            state.isLoading = true;
        },
        getTeamByIdSuccess: (state, action) => {
            state.team = action?.payload?.data;
            state.message = null;
            state.isLoading = false;
        },
        getTeamByIdFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },

        // Update Team
        updateTeamStart: (state) => {
            state.isLoading = true;
        },
        updateTeamSuccess: (state, action) => {
            state.team = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        updateTeamFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },

        // Delete Team
        deleteTeamStart: (state) => {
            state.isLoading = false;
        },
        deleteTeamSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        deleteTeamFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },

        //PublicList
        getTeamPublicListStart: (state) => {
            state.isLoading = true;
        },
        getTeamPublicListSuccess: (state, action) => {
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false
        },
        getTeamPublicListFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },

        // Add Users To Team
        addUsersToTeamStart: (state) => {
            state.isLoading = true;
        },
        addUsersToTeamSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        addUsersToTeamFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },

        // Get Team Users
        getTeamUserStart: (state) => {
            state.isLoading = true;
            state.users = [];
            state.error = null;
            state.message = null;
        },
        getTeamUserSuccess: (state, action) => {
            state.users = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        getTeamUserFailed: (state, action) => {
            state.error = action?.payload?.data;
            state.users = []
            state.isLoading = false;
        },

        // Get Teamleaderboard
        getTeamLeaderboardStart: (state) => {
            state.isLoading = true;
            state.leaderboard = [];
            state.event = {}
        },
        getTeamLeaderboardSuccess: (state, action) => {
            state.leaderboard = action?.payload?.data?.leaderboard;
            state.event = action?.payload?.data?.event;
            state.isLoading = false;
        },
        getTeamLeaderboardFailed: (state, action) => {
            state.leaderboard = [];
            state.event = {};
            state.error = action?.payload;
            state.isLoading = false;
        },
        
        // Get Club Dashboard
        getClubLeaderboardStart: (state) => {
            state.isLoading = true;
            state.club = [];
            state.event = {}
        },
        getClubLeaderboardSuccess: (state, action) => {
            state.club = action?.payload?.data?.leaderboard;
            state.event = action?.payload?.data?.event;
            state.isLoading = false;
        },
        getClubLeaderboardFailed: (state, action) => {
            state.club = [];
            state.event = {};
            state.error = action?.payload;
            state.isLoading = false;
        },
    }
});

export const {
    getTeamListStart,
    getTeamListSuccess,
    getTeamListFailed,
    createTeamStart,
    createTeamSuccess,
    createTeamFailed,
    getTeamByIdStart,
    getTeamByIdSuccess,
    getTeamByIdFailed,
    updateTeamStart,
    updateTeamSuccess,
    updateTeamFailed,
    deleteTeamStart,
    deleteTeamSuccess,
    deleteTeamFailed,
    getTeamPublicListStart,
    getTeamPublicListSuccess,
    getTeamPublicListFailed,
    addUsersToTeamStart,
    addUsersToTeamSuccess,
    addUsersToTeamFailed,
    getTeamUserStart,
    getTeamUserSuccess,
    getTeamUserFailed,
    getTeamLeaderboardStart,
    getTeamLeaderboardSuccess,
    getTeamLeaderboardFailed,
    getClubLeaderboardStart,
    getClubLeaderboardSuccess,
    getClubLeaderboardFailed
} = TeamSlice?.actions;
export default TeamSlice?.reducer;