import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    event: null,
    leaderboard: null,
    activity: null,
    order: null,
    users: [],
    payment: null,
    message: null,
    error: null,
    addEventUser: null,
}

const EventSlice = createSlice({
    name: 'Events',
    initialState,
    reducers: {
        getEventListStart: (state) => {
            state.isLoading = true;
            state.activity = null;
        },
        getEventListSuccess: (state, action) => {
            state.data = action?.payload?.data;
            state.activity = null;
            state.message = action?.payload?.message;
            state.isLoading = false
        },
        getEventListFailed: (state, action) => {
            state.error = action?.payload;
            state.activity = null;
            state.isLoading = false
        },

        // Create Event Slice
        createEventStart: (state) => {
            state.isLoading = true;
        },
        createEventSuccess: (state, action) => {
            state.error = null;
            state.event = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        createEventFailed: (state, action) => {
            state.error = action?.payload;
            state.message = null;
            state.isLoading = false;
        },

        // Get Event By Id
        getEventByIdStart: (state) => {
            state.isLoading = true;
        },
        getEventByIdSuccess: (state, action) => {
            state.event = action?.payload?.data;
            state.message = null;
            state.isLoading = false;
        },
        getEventByIdFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },

        // Update Event
        updateEventStart: (state) => {
            state.isLoading = true;
        },
        updateEventSuccess: (state, action) => {
            state.event = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        updateEventFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },

        // Delete Event
        deleteEventStart: (state) => {
            state.isLoading = false;
        },
        deleteEventSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        deleteEventFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },

        // createOrder
        createOrderStart: (state) => {
            state.isLoading = true;
        },
        createOrderSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.order = action?.payload?.data;
            state.isLoading = false;
        },
        createOrderFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },

        registerToEventStart: (state) => {
            state.isLoading = true
        },
        registerEventSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.payment = action?.payload?.message;
            state.isLoading = action?.payload?.isLoading;
        },
        registerEventFailed: (state, action) => {
            state.error = action?.payload?.message;
            state.isLoading = false
        },

        // Leaderboard
        getLeaderboardStart: (state) => {
            state.isLoading = true;
            state.activity = null;
        },
        getLeaderboardSuccess: (state, action) => {
            state.leaderboard = action?.payload?.data;
            state.activity = null;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        getLeaderboardFailed: (state, action) => {
            state.error = action?.payload;
            state.activity = null;
            state.leaderboard = null;
            state.isLoading = false;
        },

        // Event Activity based on the Event and Registered User
        getActivityStart: (state) => {
            state.isLoading = true;
            state.activity = null;
        },
        getActivitySuccess: (state, action) => {
            state.activity = action?.payload?.data;
            state.message = action?.message;
            state.leaderboard = null;
            state.isLoading = false;
        },
        getActivityFailed:  (state, action) => {
            state.error = action?.payload;
            state.leaderboard = null;
            state.isLoading = false;
        },

        // Add User to Event
        addUserToEventStart: (state) => {
            state.addEventUser = null;
            state.message = null;
            state.isLoading = true;
        },
        addUserToEventSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.addEventUser = action?.payload?.message;
            state.isLoading = false;
        },
        addUserToEventFailed: (state, action) => {
            state.error = action?.payload;
            state.addEventUser = null;
            state.isLoading = false;
        },

        // Get Registered User
        getEventUserStart: (state) => {
            state.isLoading = true;
        },
        getEventUserSuccess: (state, action) => {
            state.users = action?.payload?.data;
            state.isLoading = false;
        },
        getEventUserFailed: (state, action) => {
            state.event = action?.payload;
            state.isLoading = false;
        },

        // complete event
        completeEventStart: (state) => {
            state.isLoading = true;
        },
        completeEventSuccess: (state, action) => {
            state.isLoading = false;
        },
        completeEventFailed: (state, action) => {
            state.isLoading = false;
        },

        // Remove User From Event
        removeUserFromEventStart: (state) => {
            state.isLoading = true;
        },
        removeUserFromEventSuccess: (state, action) => {
            state.isLoading = false;
        },
        removeUserFromEventFailed: (state) => {
            state.isLoading = false;
        }
    }
});

export const {
    getEventListStart,
    getEventListSuccess,
    getEventListFailed,
    createEventStart,
    createEventSuccess,
    createEventFailed,
    getEventByIdStart,
    getEventByIdSuccess,
    getEventByIdFailed,
    updateEventStart,
    updateEventSuccess,
    updateEventFailed,
    deleteEventStart,
    deleteEventSuccess,
    deleteEventFailed,
    createOrderStart,
    createOrderSuccess,
    createOrderFailed,
    registerToEventStart,
    registerEventSuccess,
    registerEventFailed,
    getLeaderboardStart,
    getLeaderboardSuccess,
    getLeaderboardFailed,
    getActivityStart,
    getActivitySuccess,
    getActivityFailed,
    addUserToEventStart,
    addUserToEventSuccess,
    addUserToEventFailed,
    getEventUserStart,
    getEventUserSuccess,
    getEventUserFailed,
    completeEventStart,
    completeEventSuccess,
    completeEventFailed,
    removeUserFromEventStart,
    removeUserFromEventSuccess,
    removeUserFromEventFailed
} = EventSlice?.actions;
export default EventSlice?.reducer;