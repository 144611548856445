
import { Dialog } from 'primereact/dialog';
        
import React from 'react'; 


const Modal = ({
  body=<></>,
  header=<></>,
  footer=<></>,
  visible=false,
  draggable=false,
  resizable=false,
  maximizable=false,
  onClose=null,
  style={width: '50vw'},
}) => {
  return (
    <Dialog
      style={style}
      header={header}
      footer={footer}
      onHide={() => {onClose()}}
      visible={visible}
      draggable={draggable}
      resizable={resizable}
      maximizable={maximizable}
    >
      {body}
    </Dialog>
  )
}

export default Modal;

        

