import React from 'react'
import { Checkbox } from 'primereact/checkbox';


const CheckBox = ({
    name = '',
    label = '',
    isRequired = false,
    labelClassName = '',
    checked = false,
    onChange = null,
    fieldClassName = '',
    onBlur = null,
    helperText = <></>
}) => {
  return (
    <div className={`flex flex-col gap-2 ${fieldClassName}`} >
        <div className='flex flex-row gap-2'>
            <Checkbox
                name={name || label }
                {...(onChange !== null && { onChange })}
                {...(onBlur !== null && { onBlur })}
                checked={checked}
            ></Checkbox>
            <label className={labelClassName} htmlFor={name}>{label}  
                <>
                    {
                        isRequired && <span className='text-red-600 text-xl'> *</span>
                    }
                </>
            </label>
        </div>
        {helperText}
    </div>
  )
}

export default CheckBox;