import React, { useState } from "react";

// Common Components
import Menu from "../../../components/Menu";
import Modal from "../../../components/Modal";

// ICONS
import { ICONS } from "../../../constant/types";

// Modal
import DeleteUser from "../../../feature/Modals/User/DeleteUser";
import AddUser from "../../../feature/Modals/User/AddUser";

// Router
import { useNavigate, useParams } from "react-router-dom";


const UserActions = ({ user }) => {
    const [action, setAction] = useState({
        edit: false,
        delete: false,
        toggle: false
    });
    const navigate = useNavigate();
    const { clubId } = useParams();

    const handleMenuItemClick = (e, operation) => {
        if (action?.hasOwnProperty(operation) && operation !== 'view' ) {
            setAction((action) => ({ ...action, [operation]: true }))
        }
        if (operation === 'view') {
            if (clubId) {
                navigate(`../../users/${user?.id}/details`)
            } else {
                navigate(`${user?.id}/details`)
            }
        }
    }

    return (
        <>
            <Menu 
                popup={true}
                dismissable={false}
                isButton={true}
                buttonLabel={<>{ ICONS?.THREE_DOT('h-4 w-4') }</>}
                buttonSize={'small'}
                className='w-28'
                // buttonIcon={ICONS?.ADMIN('h-4 w-4 m-2')}
                items={[
                    {
                        label: <span className="font-semibold text-sm">View</span>,
                        icon: 'pi pi-eye text-xs',
                        command: (e) => handleMenuItemClick(e, 'view')
                    },
                    {
                        label: <span className="font-semibold text-sm">Edit</span>,
                        icon: 'pi pi-pencil text-xs',
                        command: (e) => handleMenuItemClick(e, 'edit')
                    },
                    {
                        label: <span className="font-semibold text-sm">Delete</span>,
                        icon: 'pi pi-trash text-xs',
                        command: (e) => handleMenuItemClick(e, 'delete')
                    }
                ]}
            />

            {/* Modals */}
            {/* { 
                action?.edit && <Modal
                    visible={action?.edit}
                    header={<span className="text-xl font-normal">Update Club Information</span>}
                    body={<CreateClub clubId={club?.id} onClose={() => { setAction((action) => ({ ...action, edit: false })) }}/>}
                    style={{width: '70vw'}}
                    resizable={true}
                    onClose={() => { setAction((action) => ({ ...action, edit: false })) }}
                />
            }
            {
                action?.delete && <Modal 
                    visible={action?.delete}
                    header={<span className="text-xl font-normal pi pi-trash"> Delete Club - {club?.name}</span>}
                    body={<DeleteClub club={club} onClose={() => { setAction((action) => ({ ...action, delete: false }))}} />}
                    onClose={() => { setAction((action) => ({ ...action, delete: false })) }}
                /> 
            } */}
            {
                action?.edit && <Modal
                    visible={action?.edit}
                    header={<span className="text-xl">Update User ( {`${user?.name || ''}`} )</span>}
                    body={<AddUser role={user?.role} userId={user?.id} onClose={() => { setAction((action) => ({ ...action, edit: false }))}}/>}
                    onClose={() => { setAction((action) => ({ ...action, edit: false })) }}
                /> 
            }
            {
                action?.delete && <Modal 
                    visible={action?.delete}
                    header={<span className="text-xl font-normal pi pi-trash"> Delete User - {user?.name}</span>}
                    body={<DeleteUser user={user} onClose={() => { setAction((action) => ({ ...action, delete: false }))}} />}
                    onClose={() => { setAction((action) => ({ ...action, delete: false })) }}
                /> 
            }
        </>
    )
}

export default UserActions;