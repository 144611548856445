import { put, call } from 'redux-saga/effects';

// Reducers
import { 
    getEventListSuccess, 
    getEventListFailed, 
    createEventSuccess, 
    createEventFailed, 
    updateEventSuccess,
    updateEventFailed, 
    deleteEventSuccess, 
    deleteEventFailed, 
    getEventByIdSuccess, 
    getEventByIdFailed, 
    getEventListStart, 
    createOrderSuccess,
    createOrderFailed,
    registerEventFailed,
    registerToEventStart,
    registerEventSuccess,
    getLeaderboardFailed,
    getLeaderboardSuccess,
    getActivityFailed,
    getActivitySuccess,
    addUserToEventSuccess,
    addUserToEventFailed,
    getEventUserSuccess,
    getEventUserFailed,
    completeEventSuccess,
    completeEventFailed,
    getLeaderboardStart,
    removeUserFromEventFailed,
    removeUserFromEventSuccess,
    getEventUserStart
} from '../../slices/EventSlice';

// Services
import {
    getEventList,
    createEvent, 
    getEventById, 
    updateEvent, 
    deleteEvent, 
    createOrder,
    registerToEvent,
    getEventLeaderboard,
    getEventActivity,
    addUserToEvent,
    getEventUsers,
    completeEvent,
    removeUserFromEvent
} from '../../services/requests';

export function* getEventListHandler(actions) {
    try {
        const response = yield call(getEventList, actions?.payload);
        yield put(getEventListSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getEventListFailed(errResponse))
    }
}

export function* createEventHandler(actions) {
    try {
        const response = yield call(createEvent, actions?.payload);
        yield put(createEventSuccess(response?.data));
        if (response?.data) {
            yield put(getEventListStart({
                page: 1,
                count: 10,
                search: '',
                isActive: true,
                isDeleted: false
            }))
        }
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(createEventFailed(errResponse))
    }
}

export function* getEventByIdHandler(actions) {
    try {
        const response = yield call(getEventById, actions?.payload);
        yield put(getEventByIdSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getEventByIdFailed(errResponse))
    }
}

export function* updateEventHandler(actions) {
    try {
        const response = yield call(updateEvent, actions?.payload);
        yield put(updateEventSuccess(response?.data));
        yield put(getEventListStart({
            page: 1,
            count: 5,
            search: '',
            isActive: true,
            isDeleted: false
        }));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(updateEventFailed(errResponse))
    }
}

export function* deleteEventHandler(actions) {
    try {
        const response = yield call(deleteEvent, actions?.payload);
        yield put(deleteEventSuccess(response?.data));
        yield put(getEventListStart({
            page: 1,
            count: 5,
            search: '',
            isActive: true,
            isDeleted: false
        }));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(deleteEventFailed(errResponse))
    }
}

export function* createOrderHandler(actions) {
    try {
        const response = yield call(createOrder, actions?.payload);
        yield put(createOrderSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(createOrderFailed(errResponse))
    }
}

export function* registerEventHandler(actions) {
    try {
        const response = yield call(registerToEvent, actions?.payload);
        yield put(registerEventSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(registerEventFailed(errResponse));
    }
}


export function* eventLeaderboardHandler(actions) {
    try {
        const response = yield call(getEventLeaderboard, actions?.payload);
        yield put(getLeaderboardSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getLeaderboardFailed(errResponse));
    }
} 

export function* eventEventActivityHandler(actions) {
    try {
        const response = yield call(getEventActivity, actions?.payload);
        yield put(getActivitySuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getActivityFailed(errResponse));
    }
} 

export function* addUserToEventHandler(actions) {
    try {
        const response = yield call(addUserToEvent, actions?.payload);
        yield put(addUserToEventSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(addUserToEventFailed(errResponse));
    }
} 

export function* getEventUsersHandler(actions) {
    try {
        const response = yield call(getEventUsers, actions?.payload);
        yield put(getEventUserSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getEventUserFailed(errResponse));
    }
}

export function* completeEventHandler(actions) {
    try {
        const response = yield call(completeEvent, actions?.payload);
        yield put(completeEventSuccess(response?.data));
        yield put(getLeaderboardStart({ eventId: actions?.payload?.eventId }))
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(completeEventFailed(errResponse));
    }
}

export function* removeUserFromEventHandler(actions) {
    try {
        const response = yield call(removeUserFromEvent, actions?.payload);
        yield put(removeUserFromEventSuccess(response?.data));
        yield put(getEventUserStart({ eventId: actions?.payload?.eventId }));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(removeUserFromEventFailed(errResponse));
    }
}