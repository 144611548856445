import { put, call } from 'redux-saga/effects';

// Reducers
import { registerStartSuccess, registerStartFailed, confirmationStartSuccess, confirmationStartFailed,  imageUploadSuccess, imageUploadFailure, loginStart, loginSuccess, loginFailed ,
    forgotPasswordSuccess , forgotPasswordFailed,
    updatePasswordSuccess,
    updatePasswordFailed,
    getUserProfileSuccess,
    getUserProfileFailed,
    updateProfileSuccess,
    updateProfileFailed,
    getUserProfileStart
} from '../../slices/AuthSlice';

// Services
import { register, uploadImage, confirmation, login , forgotPassword, updatePassword, getProfile, updateProfile } from '../../services/requests';

export function* registerHandler(actions) {
    try {
        const response = yield call(register, actions?.payload);
        yield put(registerStartSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(registerStartFailed(errResponse))
    }
}

export function* confirmationHandler(actions) {
    try {
        const response = yield call(confirmation, actions?.payload);
        yield put(confirmationStartSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(confirmationStartFailed(errResponse))
    }
}

export function* forgotPasswordHandler(actions) {
    try {
        const response = yield call(forgotPassword, actions?.payload);
        yield put(forgotPasswordSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(forgotPasswordFailed(errResponse))
    }
}

export function* imageUploadHandler(actions) {
    try {
        let response = yield call(uploadImage, actions?.payload);
        yield put(imageUploadSuccess(response?.data));
    } catch (err) {
        const errResponse = err?.response?.data?.message;
        yield put(imageUploadFailure(errResponse));
    }
}

export function* loginHandler(actions) {
    try {
        let response = yield call(login, actions?.payload);
        yield put(loginSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(loginFailed(errResponse));
    }
}

export function* updatePasswordHandler(actions) {
    try {
        let response = yield call(updatePassword, actions?.payload);
        yield put(updatePasswordSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(updatePasswordFailed(errResponse));
    }
}


export function* getProfileHandler(actions) {
    try {
        let response = yield call(getProfile, actions?.payload);
        yield put(getUserProfileSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getUserProfileFailed(errResponse));
    }
}

export function* updateProfileHandler(actions) {
    try {
        let response = yield call(updateProfile, actions?.payload);
        yield put(updateProfileSuccess(response?.data));
        yield put(getUserProfileStart())
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(updateProfileFailed(errResponse));
    }
}
