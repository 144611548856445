import React, { forwardRef } from "react";

// Prime React Component
import Button from "../Button";

import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';


const Steppers = forwardRef(({
    steps = [],
    stepperStyle = {},
    stepperClassName = '',
    onChangeStep = null,
    activeStep = 1,
    orientation = 'horizontal'
}, ref) => {
    return (
        <Stepper ref={ref} activeStep={activeStep} onChangeStep={(e) => {onChangeStep(e)}} style={stepperStyle} orientation={orientation}>
            {
                steps?.map((step) => (
                    <StepperPanel key={step?.id}  header={ step?.header } >
                        { step?.body }
                    </StepperPanel>
                ))
            }
        </Stepper>
    )
});


export default Steppers;