import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';   
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { InputOtp } from 'primereact/inputotp';  
import { RadioButton } from 'primereact/radiobutton';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import {InputTextarea} from 'primereact/inputtextarea'
import { FloatLabel } from 'primereact/floatlabel';
        

const FIELD_TYPES = {
  otp: 'otp',
  text: 'text',
  mask: 'mask',
  switch: 'switch',
  number: 'number',
  textarea: 'textarea',
  password: 'password',
  checkbox: 'checkbox',
  radioButton: 'radioButton'
}

const InputFields = ({
    min=null,
    max=100,
    name='',
    value='',
    label=<></>,
    locale='',
    suffix='',
    header='',
    prefix='',
    style={boxShadow: 'none'},
    mask='',
    slotChar='',
    checked='',
    keyfilter='',
    variant = '',
    weakLabel='',
    mediumLabel='',
    promptLabel='',
    strongLabel='',
    className='',
    placeholder='',
    onValueChange='',
    fieldClassName='',
    decrementButtonClassName='',
    incrementButtonClassName='',
    incrementButtonIcon='',
    decrementButtonIcon='',
    labelClassName='',
    buttonLayout='',
    inputStyle={width: '100%', boxShadow: 'none'},
    mode='decimal', 
    currency='USD',    
    type = 'text',
    length=8,
    rows=0,
    cols=0,
    tabIndex=0,
    minFractionDigits=0,
    maxFractionDigits=0,
    toggleMask=false,
    feedback=false,
    useGrouping=false,
    disabled=false,
    onChange = null,
    onBlur = null,
    showButtons = false,
    invalid = false,
    integerOnly=false,
    autoResize=false,
    isFloat=false,
    isRequired=false,
    helperText = <></>
}) => {

  const renderInputField = () => {
    switch (type) {
      case FIELD_TYPES.text:
        
        return (
            <InputText 
              name={name} 
              value={value} 
              className={className}
              onChange={(e) => { onChange(e) }}  
              onBlur={(e) => { onBlur !== null ? onBlur(e) : console.log('No Blur') }}
              variant={variant} 
              keyfilter={keyfilter} 
              placeholder={placeholder} 
              disabled={disabled}
              style={style}
              
            />
            
        );

      case FIELD_TYPES.switch:
        return (
          
            <InputSwitch
              name={name} 
              checked={checked} 
              onChange={(e) => { onChange(e) }} 
              onBlur={(e) => { onBlur !== null ? onBlur(e) : console.log('No Blur') }}
              disabled={disabled}
            />
            
          
        );

      case FIELD_TYPES.number:
        return (
         
            <InputNumber
              name={name}  
              value={value} 
              placeholder={placeholder}
              onChange={(e) => {onChange(e)}} 
              onBlur={(e) => { onBlur !== null ? onBlur(e) : console.log('No Blur') }}
              // minFractionDigits={minFractionDigits} 
              // maxFractionDigits={maxFractionDigits}
              // min={min} 
              // max={max} 
              // locale={locale} 
              disabled={disabled} 
              // mode={mode} 
              // currency={currency} 
              // suffix={suffix} 
              // prefix={prefix} 
              // decrementButtonClassName={decrementButtonClassName} 
              // incrementButtonClassName={incrementButtonClassName} 
              style={style} 
              // useGrouping={useGrouping}
              // incrementButtonIcon={incrementButtonIcon} 
              // decrementButtonIcon={decrementButtonIcon} 
              // buttonLayout={buttonLayout} 
              // showButtons={showButtons} 
              invalid={invalid}
            />
           
          
        );

      case FIELD_TYPES.otp:
        return (
       
            <InputOtp
              name={name}  
              value={value} 
              onChange={(e) => {onChange(e)}} 
              onBlur={(e) => { onBlur !== null ? onBlur(e) : console.log('No Blur') }}
              integerOnly={integerOnly} 
              length={length} 
              style={style} 
              className={className}
            />
           
        );

      case FIELD_TYPES.textarea:
        return (
       
            <InputTextarea
              name={name}  
              autoResize={autoResize}
              placeholder={placeholder} 
              value={value} 
              onChange={(e) => {onChange(e)}} 
              onBlur={(e) => { onBlur !== null ? onBlur(e) : console.log('No Blur') }}
              rows={rows} 
              cols={cols}  
              className={className}
              variant={variant} 
              invalid={invalid} 
              style={style}
              disabled={disabled}
            />
         
        );

      case FIELD_TYPES.mask:
        return (
        
            <InputMask
              name={name}  
              value={value} 
              onChange={(e) => {onChange(e)}} 
              onBlur={(e) => { onBlur !== null ? onBlur(e) : console.log('No Blur') }}
              mask={mask} 
              placeholder={placeholder} 
              slotChar={slotChar}
              invalid={invalid} 
              disabled={disabled} 
            />
           
        );

      case FIELD_TYPES.password:
        return (
            <Password
              name={name}  
              value={value} 
              onChange={(e) => {onChange(e)}} 
              onBlur={(e) => { onBlur !== null ? onBlur(e) : console.log('No Blur') }}
              feedback={feedback} 
              tabIndex={tabIndex} 
              placeholder={placeholder}
              promptLabel={promptLabel} 
              weakLabel={weakLabel} 
              inputStyle={inputStyle}
              mediumLabel={mediumLabel} 
              strongLabel={strongLabel}
              toggleMask={toggleMask}
              header={header} 
              footer={header} 
              variant={variant} 
              invalid={invalid} 
              disabled={disabled} 
              style={style}
            />
        );
      case FIELD_TYPES.radioButton:
        return (
         
            <RadioButton  
              name={name} 
              value={value} 
              onChange={(e) => {onChange(e)}} 
              onBlur={(e) => { onBlur !== null ? onBlur(e) : console.log('No Blur') }}
              checked={checked} 
              variant={variant} 
              invalid={invalid} 
              disabled={disabled}
            />
         
        );

      case FIELD_TYPES.checkbox:
        return (
         
            <Checkbox 
              onChange={(e) => {onChange(e)}} 
              onBlur={(e) => { onBlur !== null ? onBlur(e) : console.log('No Blur') }}
              checked={checked}  
              name={name} 
              value={value} 
              variant={variant} 
              invalid={invalid} 
              disabled={disabled}
            />
          
        );

      default:
        return null;
    }
  };

  const renderInputWithOptionalFloat = (inputElement) => {
    return isFloat ? <FloatLabel>{inputElement}</FloatLabel> : inputElement;
  };

  return (
    <>
      {
        renderInputWithOptionalFloat(
          <div className={`flex flex-col gap-2 ${fieldClassName}`}>
            {isFloat ? 
              <></> : 
              <label className={labelClassName} htmlFor={name}>{
                label}  
                <>
                  {
                    isRequired && <span className='text-red-600 text-xl'> *</span>
                  }
                </>
              </label>
            }
            {renderInputField()}
            {isFloat ? 
              <label htmlFor={name}>
                {label}
                <>
                  {
                    isRequired && <span className='text-red-600 text-xl'> *</span>
                  }
                </>
              </label> : <></> }
            {helperText}
          </div>
        )
      }
    </>
  );
}

export default InputFields;