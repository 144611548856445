import React, { useState } from 'react'

// Common Components
import Modal from '../../../components/Modal';
import Loader from '../../../components/Loader';
import Button from '../../../components/Button';

// Icons
import { ICONS } from '../../../constant/types';

// Store
import { useDispatch, useSelector } from 'react-redux';
import { removeUserFromEventStart } from '../../../store/slices/EventSlice';


const EnrolledUserAction = ({ user }) => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state?.event);
    const [isOpenModal, setOpenModal] = useState(false);

    const removeUserFromEvent = () => {
        try {
            if (!user?.eventId || !user?._id) return;

            dispatch(removeUserFromEventStart({ eventId: user?.eventId, userId: user?._id }))
            setOpenModal(false);
        } catch(err) {
            throw err;
        }
    }
    return (
        <>
            <Loader isVisible={isLoading} />
            <Button label='' size='small' icon={ICONS?.REMOVE_USER} onClick={() => { setOpenModal(true) }} />

            {
                isOpenModal && 
                <Modal 
                    visible={isOpenModal}
                    // header={<p>Remove {user?.name} from event</p>}
                    body={
                        <>
                            <div className='flex flex-col justify-center items-center'>
                                <p className='font-bold text-xl'>Are you sure ?</p>
                                <p>Do you want to remove <span className='font-semibold'>{user?.name}</span> from event</p>
                                <div className='flex justify-between gap-4 my-8'>
                                    <Button 
                                        label='Remove' 
                                        className='bg-red-700 border-none' 
                                        icon={ICONS?.DELETE('h-6 w-6 mx-1')}
                                        onClick={() => { removeUserFromEvent() }} 
                                    />
                                    <Button 
                                        label='Cancel' 
                                        className='border-none' 
                                        icon={ICONS?.CANCEL('h-6 w-6 mx-1')} 
                                        onClick={() => { setOpenModal(false) }}
                                    />
                                </div>
                            </div>
                        </>
                    }
                    onClose={() => { setOpenModal(false) }}
                />
            }
        </>
    )
}

export default EnrolledUserAction; 