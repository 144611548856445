import React, { useState, useEffect, useRef } from 'react'

// Formik
import { useFormik } from 'formik'

// Common Components
import InputField from '../../../components/InputField'
import Toast from '../../../components/Toast';
import Loader from '../../../components/Loader';
import Stepper from '../../../components/Stepper';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import CheckBox from '../../../components/Checkbox';

// Utils
import { VALIDATION } from '../../../utils/validation';
import { TABLE, toastMessageBuilder, USER_ROLES } from '../../../utils/utils';

// Slice
import { getCountryListStart } from '../../../store/slices/CountrySlice'

// Store
import { useDispatch, useSelector } from 'react-redux';
import { createUserStart, getUserByIdStart, updateUserStart } from '../../../store/slices/UserSlice';
import { useParams } from 'react-router-dom';

const AddUser = ({ role,  userId, onClose = null}) => {
    const dispatch = useDispatch();
    const { clubId } = useParams();

    const [currentStep, setCurrentStep] = useState(0);
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        phoneCode: '+91',
        role: role || 'USER',
        age: '',
        gender: '',
        bloodGroup: '',
        healthIssues: false,
        previouslyInjured: false,
        agreedTermsAndCondition: false,
        healthIssueDescription: '',
        address: {
            street: '',
            city: '',
            state: '',
            country: 'India',
            pincode: ''
        }
    })
    const [isUserCreated, setIsUserCreated] = useState();
    const userStore = useSelector((state) => state?.user);
    const stepperRef = useRef(null);
    const toasts = useRef(null);
    const formik = useFormik({
        initialValues: user,
        validationSchema: VALIDATION?.USER?.CREATE
    });

    useEffect(() => {
        if (userId) {
            dispatch(getUserByIdStart({ userId }))
        }
    }, [userId])

    useEffect(() => {
        if (userId && Object.keys(userStore?.user || {})?.length) {
            setUser((user) => ({
                ...user,
                ...userStore?.user
            }))
            formik?.setValues({ ...userStore?.user, password: 'Test@1234' })
        }
    }, [userStore?.user])

    useEffect(() => {
        fetchCountry();
    }, [dispatch])

    useEffect(() => {
        if (userStore.error === null && userStore.message != null && isUserCreated) {
          const timer = setTimeout(() => {
            toasts?.current?.show(toastMessageBuilder({
                title: role === USER_ROLES[2] ? 'Admin' : 'User',
                message: role === USER_ROLES[2] ? 'Admin Created Successfully' : 'User Created Successfully',
            }))
            onClose();
          }, 1000);
          return () => clearTimeout(timer);
        }

        if (userStore?.error) {
            toasts?.current?.show(toastMessageBuilder({
                title: role === USER_ROLES[2] ? 'Admin' : 'User',
                message: userStore?.error
            }))   
        }
      }, [userStore]);

    const fetchCountry = () => {
        dispatch(getCountryListStart());
    }

    const onNextStepHandler = () => {
        stepperRef.current.nextCallback()
    };

    const onPreviousStepHandler = () => {
        stepperRef.current.prevCallback()
    };

    const onSubmitHandler = () => {
        const createUserPayload = { ...formik?.values };
        if (clubId) {
            createUserPayload['club'] = clubId
        }
        if (!createUserPayload?.gender) {
            createUserPayload.gender = 'PREFER_NOT_TO_SAY'
        }
        if (userId) {
            delete createUserPayload?.password;
            createUserPayload['userId'] = userId;
            dispatch(updateUserStart(createUserPayload));
        } else {
            dispatch(createUserStart(createUserPayload))
        }
        setIsUserCreated(true)
    }
    return (
        <>
            <Toast ref={toasts}/>
            <Loader isVisible={userStore?.isLoading} />
            <Stepper 
                activeStep={currentStep}
                ref={stepperRef}
                onChangeStep={(e) => {setCurrentStep(e?.index)}}
                steps={
                    [
                        {
                            id: 'basic-info',
                            header: <p>User Information</p>,
                            body: <BasicInformation formik={formik} userId={userId} onNextStep={onNextStepHandler}/>
                        },
                        {
                            id: 'address-info',
                            header: <p>Address Information</p>,
                            body: <AddressInformation role={role} formik={formik} onNextStep={onNextStepHandler} onPrevStep={onPreviousStepHandler} onSubmit={onSubmitHandler} userId={userId}/>
                        }
                    ]
                }
            />
        </>
    )
}


const BasicInformation = ({ formik, userId, onNextStep }) => {
    
    const onCheckBoxHandler = (e) => {
        try {
          formik.setFieldValue(e?.target?.name, e?.target?.checked)
        } catch(err) {
          throw err;
        }
    }
    
    return (
        <div className='flex flex-col gap-8'>
            <div className='flex md:flex-row flex-col justify-between gap-4'>
                <div className='w-full'>
                    <InputField
                        label={"First Name"}
                        name="firstName"
                        type={"text"}
                        labelClassName="text-sm"
                        className={`${
                        formik.touched?.firstName && formik.errors?.firstName
                            ? "border border-red-600 w-full"
                            : "w-full"
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                        helperText={
                        formik.touched?.firstName && formik.errors?.firstName ? (
                            <span className="text-sm text-red-700">
                            {formik.errors?.firstName}
                            </span>
                        ) : (
                            <></>
                        )
                        }
                    />
                </div>
                <div className='w-full'>
                    <InputField
                        label={"Last Name"}
                        name="lastName"
                        type={"text"}
                        labelClassName="text-sm"
                        className={`${
                        formik.touched?.lastName && formik.errors?.lastName
                            ? "border border-red-600 w-full"
                            : "w-full"
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                        helperText={
                        formik.touched?.lastName && formik.errors?.lastName ? (
                            <span className="text-sm text-red-700">
                            {formik.errors?.lastName}
                            </span>
                        ) : (
                            <></>
                        )
                        }
                    />
                </div>
            </div>
            <div className='flex md:flex-row flex-col justify-between gap-4'>
                <div className='w-full'>
                    <InputField
                        label={"Email"}
                        name="email"
                        type={"text"}
                        labelClassName="text-sm"
                        className={`${
                            formik.touched?.email && formik.errors?.email
                            ? "border border-red-600 w-full"
                            : "w-full"
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.email}
                        helperText={
                            formik.touched?.email && formik.errors?.email ? (
                            <span className="text-sm text-red-700">
                                {formik.errors?.email}
                            </span>
                            ) : (
                            <></>
                            )
                        }
                    />
                </div>
                {!userId ? <div className='w-full'>
                    <InputField
                        label={"Password"}
                        name="password"
                        type={"password"}
                        labelClassName="text-sm"
                        className={`${
                            formik.touched?.phone && formik.errors?.phone
                            ? "border border-red-600 w-full"
                            : "w-full"
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        helperText={
                            formik.touched?.password && formik.errors?.password ? (
                            <span className="text-sm text-red-700">
                                {formik.errors?.password}
                            </span>
                            ) : (
                            <></>
                            )
                        }
                    />
                </div> : <></> }
                <div className='w-full'>
                    <InputField
                        label={"Phone Number"}
                        name="phone"
                        type={"text"}
                        labelClassName="text-sm"
                        className={`${
                            formik.touched?.phone && formik.errors?.phone
                            ? "border border-red-600 w-full"
                            : "w-full"
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                        helperText={
                            formik.touched?.phone && formik.errors?.phone ? (
                            <span className="text-sm text-red-700">
                                {formik.errors?.phone}
                            </span>
                            ) : (
                            <></>
                            )
                        }
                    />
                </div>
            </div>
            <div className='flex md:flex-row flex-col justify-between gap-4'>
                <div className='w-full'>
                    <InputField
                        label={"Age"}
                        name="age"
                        type={"text"}
                        labelClassName="text-sm"
                        className={`${
                        formik.touched?.firstName && formik.errors?.firstName
                            ? "border border-red-600 w-full"
                            : "w-full"
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.age}
                        helperText={
                        formik.touched?.firstName && formik.errors?.age ? (
                            <span className="text-sm text-red-700">
                            {formik.errors?.age}
                            </span>
                        ) : (
                            <></>
                        )
                        }
                    />
                </div>
                <div className='w-full'>
                    <InputField
                        label={"Blood Group"}
                        name="bloodGroup"
                        type={"text"}
                        labelClassName="text-sm"
                        className={`${
                        formik.touched?.bloodGroup && formik.errors?.bloodGroup
                            ? "border border-red-600 w-full"
                            : "w-full"
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.bloodGroup}
                        helperText={
                        formik.touched?.bloodGroup && formik.errors?.bloodGroup ? (
                            <span className="text-sm text-red-700">
                            {formik.errors?.bloodGroup}
                            </span>
                        ) : (
                            <></>
                        )
                        }
                    />
                </div>
            </div>
            <div className='flex md:flex-row flex-col justify-between gap-4'>
                <div className='w-full'>
                    <CheckBox 
                        name="healthIssues" 
                        label="Health Issue" 
                        isRequired={false} 
                        onChange={(e) => { onCheckBoxHandler(e) }} 
                        checked={formik?.values?.healthIssues}
                    />
                </div>
                <div className="w-full">
                    <CheckBox name="previouslyInjured" label="Previous injury" isRequired={false} onChange={(e) => { onCheckBoxHandler(e) }} checked={formik?.values?.previouslyInjured}/>
                </div>
            </div>
            {
                formik?.values?.healthIssues ? 
                <div className='flex md:flex-row flex-col justify-between gap-4'>
                    <div className='w-full'>
                        <InputField
                            name='healthIssueDescription' 
                            label={'Health Issues Note'} 
                            placeholder={'Enter the user health issues'} 
                            type='textarea' 
                            rows={4}
                            onChange={formik?.handleChange}
                            onBlur={formik?.handleBlur}
                            value={formik?.values?.healthIssueDescription}
                            helperText={
                                formik?.healthIssues ? (
                                    <span className="text-sm text-red-700">
                                    {formik.errors?.healthIssueDescription}
                                    </span>
                                ) : (
                                    <></>
                                )
                            }
                        />
                    </div>
                </div> 
                : <></>
            }
            <div className="flex justify-end gap-4 mt-8">
                <Button label="Cancel" className="" outline={true}/>
                <Button label="Next" onClick={() => {onNextStep()}} disabled={
                    !(formik?.values?.firstName && formik?.values?.lastName && formik?.values?.email && formik?.values?.phone && formik?.values?.password)
                } />
            </div>
        </div>
    )
}

const AddressInformation = ({ role, formik, userId, onNextStep, onPrevStep, onSubmit }) => {
    const { values, errors, touched, handleChange, handleBlur } = formik;
    const dispatch = useDispatch();
    const states = useSelector((state) => state?.country);
    const [city, setCity] = useState();
    useEffect(() => {
        fetchCountry();
    }, [dispatch])

    const fetchCountry = () => {
        dispatch(getCountryListStart());
    }

    const onStateHandler = (event) => {
        try {
            const selectedState = states?.data?.states?.find((state) => state?.name === event);
            if (selectedState) {
                formik?.setFieldValue('address.state', selectedState?.name)
                setCity(selectedState?.city);
            }
        } catch(err) {
            throw err;
        }
    }
    
    const onCityHandler = (event) => {
        try {
            formik?.setFieldValue('address.city', event);
        } catch(err) {
            throw err;
        }
    }

    return (
        <>
            <div className="flex flex-col gap-8">
                <InputField 
                    label={'Street'}
                    isRequired={true}
                    name={'address.street'}
                    value={values?.address?.street}
                    placeholder="Gemini Parsn Apartments, 25F, 2nd Floor, Cathedral Garden Rd, Nungambakkam"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`${touched?.address?.street && errors?.address?.street ? 'border border-red-600' : ''}`}
                    helperText={touched?.address?.street && errors?.address?.street ? <span className="text-sm text-red-700">{ errors?.address?.street }</span> : <></>}
                />
                <Select 
                    label={'State'}
                    isRequired={true}
                    value={values?.address?.state} 
                    optionLabel={'name'} 
                    onBlur={handleBlur}
                    options={TABLE?.STATE?.FORMATED(states?.state)}
                    onSelect={(event) => { onStateHandler(event) }}
                    className={`${touched?.address?.state && errors?.address?.state ? 'border border-red-600' : ''}`}
                    helperText={touched?.address?.state && errors?.address?.state ? <span className="text-sm text-red-700">{ errors?.address?.state }</span> : <></>}
                />
                <div className="flex justify-between gap-6">
                    <Select
                        className="mt-1"
                        selectClassName="w-full"
                        isRequired={true} 
                        label={'City'} 
                        optionLabel={'name'} 
                        value={values?.address?.city}
                        options={TABLE?.CITY?.FORMATED(city)}
                        onSelect={(event) => { onCityHandler(event) }}
                    />
                    <InputField
                        name={'address.country'} 
                        label={'Country'}
                        isRequired={true}
                        value={values?.address?.country}
                        placeholder={'India'}
                        fieldClassName="w-full"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`${touched?.address?.country && errors?.address?.country ? 'border border-red-600' : ''}`}
                        helperText={touched?.address?.country && errors?.address?.country ? <span className="text-sm text-red-700">{ errors?.address?.country }</span> : <></>}
                    />
                     <InputField
                        name={'address.pincode'} 
                        label={'Pincode'}
                        isRequired={true}
                        value={values?.address?.pincode} 
                        placeholder={'600088'}
                        fieldClassName="w-full"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`${touched?.address?.pincode && errors?.address?.pincode ? 'border border-red-600' : ''}`}
                        helperText={touched?.address?.pincode && errors?.address?.pincode ? <span className="text-sm text-red-700">{ errors?.address?.pincode }</span> : <></>}
                    />
                </div>
            </div>
            <div className="flex justify-end gap-4 mt-12">
                <Button label="Back" onClick={() => { onPrevStep() }} outline={true}/>
                <Button label={`${userId? 'Update' : 'Create'} ${role === USER_ROLES[2] ? 'Admin': 'User'}`} onClick={() => { onSubmit() }} disabled={!(values?.address?.street && values?.address?.city && values?.address?.state && values?.address?.country && values?.address?.pincode )} />
            </div>
        </>
    )
}

export default AddUser