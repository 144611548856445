import React, { Fragment } from 'react';

import { ColorRing, Triangle, TailSpin, InfinitySpin, RotatingLines, Oval } from 'react-loader-spinner';
import { IMAGES } from '../../constant/types';

const Loader = ({ 
    // type = 'tailSpin', 
    type = 'oval', 
    isVisible = false, 
    height = '200', 
    width = '200', 
    color = '#1d4ed8', 
    radius = 1, 
    isModal = false,
    overall = true 
}) => {

    const loaderClass = isModal ? 'fixed inset-0 absolute bg-opacity-90' : 'fixed inset-0 bg-opacity-80';

    // fixed inset-0 flex items-center justify-center z-50 bg-gray-200 bg-opacity-80

  return (
    <Fragment>
        {
            isVisible && <div className={
                `${overall ? `${loaderClass} flex items-center justify-center z-50 bg-gray-200` : 'flex items-center justify-center'}`
                }>
                <div className="rounded-full h-24 w-24 flex items-center justify-center">
                    {
                        type === 'triangle' && <Triangle 
                            visible={isVisible} 
                            height={height} 
                            width={width} 
                            color={color}
                        />
                    }
                    {
                        type === 'tailSpin' && <TailSpin
                            height={height}
                            width={width}
                            color={color}
                            ariaLabel="tail-spin-loading"
                            radius={radius}
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={isVisible}
                      />
                    } 
                    {
                        type === 'infinitySpin' && <InfinitySpin
                        visible={true}
                        width={width}
                        color={color}
                        ariaLabel="infinity-spin-loading"
                        />
                    } 
                    {
                        type === 'rotatingLines' && <RotatingLines
                        visible={true}
                        height={height}
                        width={width}
                        color={color}
                        strokeWidth="5"
                        strokeColor={color}
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        />
                    }         
                    {
                        type === 'oval' && <div className="relative w-32 h-32">
                        {/* Logo */}
                        <img
                          src={IMAGES?.LOGO}
                          alt="Logo"
                          className="absolute inset-0 w-20 h-20 m-auto z-10 rounded-full mt-16" // Centering logo using Tailwind utilities
                        />
                  
                        {/* Spinner */}
                        <TailSpin
                          height={height}
                          width={width}
                          color={'#DC143C'}
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor={'#002147'}
                          strokeWidth={4}
                          strokeWidthSecondary={4}
                          
                        />
                      </div>
                    } 
                </div>
            </div>
        }
    </Fragment>
  )
}

export default Loader