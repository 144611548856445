import { put, call } from 'redux-saga/effects';
import { getActivities } from "../../services/requests";
import { getActivityFailed, getActivitySuccess, getLastActivitySuccess, getLastActivityFailed } from "../../slices/ActivitySlice";

// Handlers
export function* getActivityListHandler(actions) {
    try {
        const response = yield call(getActivities, actions?.payload);
        yield put(getActivitySuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getActivityFailed(errResponse))
    }
}


export function* getLastActivityHandler(actions) {
    try {
        const response = yield call(getActivities, actions?.payload);
        yield put(getLastActivitySuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getLastActivityFailed(errResponse))   
    }
}
