import React, { useState, useRef, useEffect } from "react";

// Formik
import { useFormik } from "formik";

// Common Components
import InputField from "../../../components/InputField";
import Stepper from "../../../components/Stepper";
import Button from "../../../components/Button";
import Select from "../../../components/Select";
import Toast from "../../../components/Toast";
import Loader from "../../../components/Loader";

// Validations
import { VALIDATION } from "../../../utils/validation";

// Reducer
import { useDispatch, useSelector } from "react-redux";

// Slice
import { getCountryListStart } from "../../../store/slices/CountrySlice";

// Utils
import { TABLE, toastMessageBuilder } from "../../../utils/utils";
import { createClubStart, getClubByIdStart, updateClubStart } from "../../../store/slices/ClubSlice";



const CreateClub = ({ onClose, clubId }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const clubs = useSelector((state) => state?.club);
    const dispatch = useDispatch();
    const toasts = useRef(null);
    const [isClubCreated, setIsClubCreate] = useState(false);
    const [club, setClub] = useState({
        name: '',
        descriptions: '',
        email: '',
        phone: '',
        phoneCode: '+91',
        logo: "https://ik.imagekit.io/indiadev2024/APPS/LOGOS/club_default_logo.png?updatedAt=1726994909045",
        address: {
            street: '',
            city: '',
            state: '',
            country: 'India',
            pincode: ''
        }
    })
    const stepperRef = useRef(null);
    
    const formik = useFormik({
        initialValues: club,
        validationSchema: VALIDATION?.CLUB?.CREATE
    })

    useEffect(() => {
        if (clubId) {
            dispatch(getClubByIdStart({ clubId }))
        }
    }, [clubId]);

    useEffect(() => {
        if (clubId && Object.keys(clubs?.club || {})?.length) {
            setClub((club) => ({
                ...club,
                ...clubs?.club
            }))
            formik?.setValues(clubs?.club);
        }
    }, [clubs?.club])

    useEffect(() => {
        if (
            clubs?.error === null && 
            clubs?.message && 
            isClubCreated
        ) {
            toasts?.current?.show(
                toastMessageBuilder({
                    title: 'Club',
                    message: clubs?.message
                })
            )
            onClose();
        }
    }, [clubs?.error, clubs?.message])

    const onNextStepHandler = () => {
        stepperRef.current.nextCallback()
    };

    const onPreviousStepHandler = () => {
        stepperRef.current.prevCallback()
    };

    const onSubmitHandler = () => {
        try {
            dispatch(createClubStart(formik?.values));
            setIsClubCreate(true)
        } catch(err) {
            throw err;
        }
    }

    const onUpdateHandler = () => {
        try {
            const updatePayload = {...formik?.values, clubId }
            dispatch(updateClubStart(updatePayload));
            setIsClubCreate(true)
        } catch(err) {
            throw err;
        }
    }
    return (
        <>
            <Toast ref={toasts}/>
            <Loader isVisible={clubs?.isLoading}  />
            <Stepper 
                activeStep={currentStep}
                ref={stepperRef}
                onChangeStep={(e) => {setCurrentStep(e?.index)}}
                steps={[
                    {
                        id: 'basic-info',
                        header: <p>Basic Information</p>,
                        body: <BasicInformation formik={formik} onNextStep={onNextStepHandler}/>
                    },
                    {
                        id: 'address-info',
                        header: <p>Address Informations</p>,
                        body: <AddressInformation formik={formik} onNextStep={onNextStepHandler} onPrevStep={onPreviousStepHandler} onSubmit={onSubmitHandler} />,
                    },
                    {
                        id: 'review-info',
                        header: <p>Review Informations</p>,
                        body: <ReviewInformation values={formik?.values} onPrevStep={onPreviousStepHandler} onSubmit={onSubmitHandler} onUpdate={onUpdateHandler} clubId={clubId}/>,
                    },
                    // {
                    //     id: 'upload-info',
                    //     header: <p>Upload Document</p>,
                    //     body: <UploadInformation formik={formik} onPrevStep={onPreviousStepHandler} onSubmit={() => {}} />
                    // }
                ]}
            />
        </>
        
    )
}


const BasicInformation = ({ formik, onNextStep }) => {
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = formik;
    return (
        <>
            <div className="flex flex-col gap-8">
                <InputField 
                    label={'Name'}
                    name={'name'}
                    isRequired={true}
                    value={values?.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Chennai Runners"
                    className={`${touched?.name && errors?.name ? 'border-2 border-red-600' : ''}`}
                    helperText={touched?.name && errors?.name ? <span className="text-sm text-red-700">{ errors?.name }</span> : <></>}
                />
                <InputField 
                    label={'Descriptions'}
                    name={'descriptions'} 
                    isRequired={true}
                    type="textarea" rows={5}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.descriptions}
                    placeholder={`Chennai Runners is a vibrant community of running enthusiasts based in Chennai. Founded in 2006. etc...`}
                    className={`${touched?.descriptions && errors?.descriptions ? 'border-2 border-red-600' : ''}`}
                    helperText={touched?.descriptions && errors?.descriptions ? <span className="text-sm text-red-700">{ errors?.descriptions }</span> : <></>}
                />
                <div className="flex justify-between gap-4">
                    <InputField
                        name={'email'} 
                        label={'Email'}
                        isRequired={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.email}
                        placeholder={'chennairunners@gmail.com'}
                        fieldClassName="w-full"
                        className={`${touched?.email && errors?.email ? 'border-2 border-red-600' : ''}`}
                        helperText={touched?.email && errors?.email ? <span className="text-sm text-red-700">{ errors?.email }</span> : <></>}
                    />
                    <InputField
                        name={'phone'} 
                        label={'Phone Number'}
                        isRequired={true}
                        value={values?.phone}
                        onChange={handleChange} 
                        onBlur={handleBlur}
                        placeholder={'9009008712'}
                        fieldClassName="w-full"
                        className={`${touched?.phone && errors?.phone ? 'border border-red-600' : ''}`}
                        helperText={touched?.phone && errors?.phone ? <span className="text-sm text-red-700">{ errors?.phone }</span> : <></>}
                    />
                </div>
            </div>
            <div className="flex justify-end gap-4 mt-8">
                <Button label="Cancel" className="" outline={true}/>
                <Button label="Next" onClick={() => {onNextStep()}} disabled={
                    !(values?.name && values?.descriptions && values?.email && values?.phone)
                } />
            </div>
        </>
    )
}

const AddressInformation = ({ formik, onNextStep, onPrevStep, onSubmit }) => {
    const { values, errors, touched, handleChange, handleBlur } = formik;
    const dispatch = useDispatch();
    const states = useSelector((state) => state?.country);
    const [city, setCity] = useState();
    
    useEffect(() => {
        fetchCountry();
    }, [dispatch])

    const fetchCountry = () => {
        dispatch(getCountryListStart());
    }

    const onStateHandler = (event) => {
        try {
            const selectedState = states?.data?.states?.find((state) => state?.name === event);
            if (selectedState) {
                formik?.setFieldValue('address.state', selectedState?.name)
                setCity(selectedState?.city);
            }
        } catch(err) {
            throw err;
        }
    }
    
    const onCityHandler = (event) => {
        try {
            formik?.setFieldValue('address.city', event);
        } catch(err) {
            throw err;
        }
    }

    return (
        <>
            <div className="flex flex-col gap-8">
                <InputField 
                    label={'Street'}
                    isRequired={true}
                    name={'address.street'}
                    value={values?.address?.street}
                    placeholder="Gemini Parsn Apartments, 25F, 2nd Floor, Cathedral Garden Rd, Nungambakkam"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`${touched?.address?.street && errors?.address?.street ? 'border border-red-600' : ''}`}
                    helperText={touched?.address?.street && errors?.address?.street ? <span className="text-sm text-red-700">{ errors?.address?.street }</span> : <></>}
                />
                <Select 
                    label={'State'}
                    isRequired={true}
                    value={values?.address?.state} 
                    optionLabel={'name'} 
                    onBlur={handleBlur}
                    options={TABLE?.STATE?.FORMATED(states?.state)}
                    onSelect={(event) => { onStateHandler(event) }}
                    className={`${touched?.address?.state && errors?.address?.state ? 'border border-red-600' : ''}`}
                    helperText={touched?.address?.state && errors?.address?.state ? <span className="text-sm text-red-700">{ errors?.address?.state }</span> : <></>}
                />
                <div className="flex justify-between gap-6">
                    <Select
                        className="mt-1"
                        selectClassName="w-full"
                        isRequired={true} 
                        label={'City'} 
                        optionLabel={'name'} 
                        value={values?.address?.city}
                        options={TABLE?.CITY?.FORMATED(city)}
                        onSelect={(event) => { onCityHandler(event) }}
                    />
                    <InputField
                        name={'address.country'} 
                        label={'Country'}
                        isRequired={true}
                        value={values?.address?.country}
                        placeholder={'India'}
                        fieldClassName="w-full"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`${touched?.address?.country && errors?.address?.country ? 'border border-red-600' : ''}`}
                        helperText={touched?.address?.country && errors?.address?.country ? <span className="text-sm text-red-700">{ errors?.address?.country }</span> : <></>}
                    />
                     <InputField
                        name={'address.pincode'} 
                        label={'Pincode'}
                        isRequired={true}
                        value={values?.address?.pincode} 
                        placeholder={'600088'}
                        fieldClassName="w-full"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`${touched?.address?.pincode && errors?.address?.pincode ? 'border border-red-600' : ''}`}
                        helperText={touched?.address?.pincode && errors?.address?.pincode ? <span className="text-sm text-red-700">{ errors?.address?.pincode }</span> : <></>}
                    />
                </div>
            </div>
            <div className="flex justify-end gap-4 mt-8">
                <Button label="Back" onClick={() => { onPrevStep() }} outline={true}/>
                <Button label="Next" onClick={() => { onNextStep() }} disabled={!(values?.address?.street && values?.address?.city && values?.address?.state && values?.address?.country && values?.address?.pincode )} />
            </div>
        </>
    )
}

const UploadInformation = ({ formik, onSubmit, onPrevStep }) => {
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = formik;
    return (
        <div>
            {/* <Upload /> */}
            {/* <input type="file" accept=".jpg,.jpeg,.png,.pdf" /> */}
        </div>
    )
}

const ReviewInformation = ({ values, onPrevStep, onSubmit, onUpdate, clubId = null }) => {
    return (
        <>
            <div className="flex flex-col gap-10">
                
                <div className="flex justify-between">
                    <div className="flex justify-start gap-4 items-center">
                        <img src={values?.logo} className="w-24 h-24 rounded-full" />
                        <div className="flex flex-col gap-1">
                            <span className="text-md font-semibold">{ values?.name }</span>
                            <span className="text-sm">{ values?.descriptions }</span>
                        </div>
                    </div>
                    <div>
                        <p className="font-semibold">Email</p>
                        <p className="text-sm">{ values?.email }</p>
                    </div>
                    <div>
                        <p className="font-semibold">Phone Number</p>
                        <p className="text-sm">{ values?.phone }</p>
                    </div>
                </div>

                <div>
                    <span className="font-bold">Address Informations</span>
                    <div className="flex flex-row justify-between gap-8">
                        <div>
                            <p className="font-semibold">Street</p>
                            <p className="text-sm">{ values?.address?.street }</p>
                        </div>
                        <div>
                            <p className="font-semibold">State</p>
                            <p className="text-sm">{ values?.address?.state }</p>
                        </div>
                        <div>
                            <p className="font-semibold">City</p>
                            <p className="text-sm">{ values?.address?.city }</p>
                        </div>
                        <div>
                            <p className="font-semibold">Pincode</p>
                            <p className="text-sm">{ values?.address?.pincode }</p>
                        </div>
                    </div>
                </div>

            </div>
    
            <div className="flex justify-end gap-4 mt-12">
                <Button label="Back" onClick={() => { onPrevStep() }} outline={true}/>
                <Button label={ clubId ? 'Update' : 'Submit'} onClick={() => { clubId ? onUpdate()  :onSubmit() }} disabled={!(values?.address?.street && values?.address?.city && values?.address?.state && values?.address?.country && values?.address?.pincode )} />
            </div>
        </>
    )
}

export default CreateClub;