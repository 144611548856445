import React, { useState } from "react";

// Common Components
import Menu from "../../../components/Menu";
import Modal from "../../../components/Modal";

// ICONS
import { ICONS } from "../../../constant/types";

// Modal
import CreateClub from "../../../feature/Modals/Club/CreateClub";
import DeleteClub from "../../../feature/Modals/Club/DeleteClub";
import { useNavigate } from "react-router-dom";


const ClubActions = ({ club }) => {
    const [action, setAction] = useState({
        edit: false,
        delete: false,
        toggle: false
    });
    const navigate = useNavigate();

    const handleMenuItemClick = (e, operation) => {

        if (operation === 'view') {
            navigate(`${club?.id}/details`)
        }
        if (action?.hasOwnProperty(operation)) {
            setAction((action) => ({ ...action, [operation]: true }))
        }
    }
    return (
        <>
            <Menu 
                popup={true}
                dismissable={false}
                isButton={true}
                buttonLabel={<>{ ICONS?.THREE_DOT('h-4 w-4') }</>}
                buttonSize={'small'}
                className='w-28'
                // buttonIcon={ICONS?.ADMIN('h-4 w-4 m-2')}
                items={[
                    {
                        label: <span className="font-semibold text-sm">View</span>,
                        icon: 'pi pi-eye text-xs',
                        command: (e) => handleMenuItemClick(e, 'view')
                    },
                    {
                        label: <span className="font-semibold text-sm">Edit</span>,
                        icon: 'pi pi-pencil text-xs',
                        command: (e) => handleMenuItemClick(e, 'edit')
                    },
                    {
                        label: <span className="font-semibold text-sm">Delete</span>,
                        icon: 'pi pi-trash text-xs',
                        command: (e) => handleMenuItemClick(e, 'delete')
                    }
                ]}
            />

            {/* Modals */}
            { 
                action?.edit && <Modal
                    visible={action?.edit}
                    header={<span className="text-xl font-normal">Update Club Information</span>}
                    body={<CreateClub clubId={club?.id} onClose={() => { setAction((action) => ({ ...action, edit: false })) }}/>}
                    style={{width: '70vw'}}
                    resizable={true}
                    onClose={() => { setAction((action) => ({ ...action, edit: false })) }}
                />
            }
            {
                action?.delete && <Modal 
                    visible={action?.delete}
                    header={<span className="text-xl font-normal pi pi-trash"> Delete Club - {club?.name}</span>}
                    body={<DeleteClub club={club} onClose={() => { setAction((action) => ({ ...action, delete: false }))}} />}
                    onClose={() => { setAction((action) => ({ ...action, delete: false })) }}
                /> 
            }
        </>
    )
}

export default ClubActions;