import { put, call } from 'redux-saga/effects';

// Reducers
import { getTeamListSuccess, getTeamListFailed, createTeamSuccess, createTeamFailed, getTeamListStart, getTeamByIdSuccess, updateTeamFailed, updateTeamSuccess, deleteTeamSuccess, deleteTeamFailed,getTeamPublicListSuccess, getTeamPublicListFailed, addUsersToTeamSuccess, addUsersToTeamFailed, getTeamUserSuccess, getTeamLeaderboardSuccess, getTeamLeaderboardFailed } from '../../slices/TeamSlice';

// Services
import { createTeam, deleteTeam, getTeamById, getTeamList, updateTeam, getTeamPublicList, addUsersToTeam, getTeamUser, getTeamleaderboard } from '../../services/requests';


export function* getTeamListHandler(actions) {
    try {
        const response = yield call(getTeamList, actions?.payload);
        yield put(getTeamListSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getTeamListFailed(errResponse))
    }
}

export function* createTeamHandler(actions) {
    try {
        const response = yield call(createTeam, actions?.payload);
        yield put(createTeamSuccess(response?.data));
        yield put(getTeamListStart({
            page: 1,
            count: 10,
            search: '',
            isActive: true,
            isDeleted: false
        }))
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(createTeamFailed(errResponse))
    }
}

export function* getTeamByIdHandler(actions) {
    try {
        const response = yield call(getTeamById, actions?.payload);
        yield put(getTeamByIdSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(createTeamFailed(errResponse))
    }
}

export function* updateTeamHandler(actions) {
    try {
        const response = yield call(updateTeam, actions?.payload);
        yield put(updateTeamSuccess(response?.data));
        yield put(getTeamListStart({
            page: 1,
            count: 5,
            search: '',
            isActive: true,
            isDeleted: false
        }));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(updateTeamFailed(errResponse))
    }
}

export function* deleteTeamHandler(actions) {
    try {
        const response = yield call(deleteTeam, actions?.payload);
        yield put(deleteTeamSuccess(response?.data));
        yield put(getTeamListStart({
            page: 1,
            count: 5,
            search: '',
            isActive: true,
            isDeleted: false
        }));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(deleteTeamFailed(errResponse))
    }
}

export function* getTeamPublicListHandler(actions) {
    try {
        const response = yield call(getTeamPublicList, actions?.payload);
        yield put(getTeamPublicListSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getTeamPublicListFailed(errResponse))
    }
}

export function* addUsersToTeamHandler(actions) {
    try {
        const response = yield call(addUsersToTeam, actions?.payload);
        yield put(addUsersToTeamSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(addUsersToTeamFailed(errResponse))
    }
}

export function* getTeamUserHandler(actions) {
    try {
        const response = yield call(getTeamUser, actions?.payload);
        yield put(getTeamUserSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getTeamListFailed(errResponse))
    }
}

export function* getTeamLeaderboardHandler(actions) {
    try {
        const response = yield call(getTeamleaderboard, actions?.payload);
        yield put(getTeamLeaderboardSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getTeamLeaderboardFailed(errResponse));
    }
} 