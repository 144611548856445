import { put, call } from 'redux-saga/effects';

// Reducers
import { 
    getUserListSuccess, 
    getUserListFailed, 
    createUserSuccess, 
    createUserFailed, 
    updateUserSuccess, 
    updateUserFailed, 
    deleteUserSuccess, 
    deleteUserFailed, 
    getUserByIdSuccess, 
    getUserByIdFailed, 
    getUserListStart,
    getUserEventByUserIdSuccess,
    getUserEventByUserIdFailed 
} from '../../slices/UserSlice';

// Services
import {
    getUserList,
    createUser, 
    getUserById, 
    updateUser, 
    deleteUser, 
    getUserEventByUserId
} from '../../services/requests';
import { USER_ROLES } from '../../../utils/utils';

export function* getUserListHandler(actions) {
    try {
        const response = yield call(getUserList, actions?.payload);
        yield put(getUserListSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getUserListFailed(errResponse))
    }
}

export function* createUserHandler(actions) {
    try {
        const response = yield call(createUser, actions?.payload);
        yield put(createUserSuccess(response?.data));
        yield put(getUserListStart({
            page: 1,
            count: 5,
            search: '',
            isActive: true,
            isDeleted: false, 
            ...( actions?.payload?.role === USER_ROLES[2] && { role: USER_ROLES[2]} ),
            ...( actions?.payload?.club  && { club: actions?.payload?.club } )
        }))
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(createUserFailed(errResponse))
    }
}

export function* getUserByIdHandler(actions) {
    try {
        const response = yield call(getUserById, actions?.payload);
        yield put(getUserByIdSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getUserByIdFailed(errResponse))
    }
}

export function* updateUserHandler(actions) {
    try {
        const response = yield call(updateUser, actions?.payload);
        yield put(updateUserSuccess(response?.data));
        yield put(getUserListStart({
            page: 1,
            count: 5,
            search: '',
            isActive: true,
            isDeleted: false,
            ...( actions?.payload?.role === USER_ROLES[2] && { role: USER_ROLES[2]} ),
            ...( actions?.payload?.club  && { club: actions?.payload?.club } )
        }));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(updateUserFailed(errResponse))
    }
}

export function* deleteUserHandler(actions) {
    try {
        const response = yield call(deleteUser, actions?.payload);
        yield put(deleteUserSuccess(response?.data));
        yield put(getUserListStart({
            page: 1,
            count: 5,
            search: '',
            isActive: true,
            isDeleted: false,
            ...( actions?.payload?.role === USER_ROLES[2] && { role: USER_ROLES[2]} ),
            ...( actions?.payload?.club  && { club: actions?.payload?.club } )
        }));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(deleteUserFailed(errResponse))
    }
}


export function* getUserEventHandler(actions) {
    try {
        const response = yield call(getUserEventByUserId, actions?.payload);
        yield put(getUserEventByUserIdSuccess(response?.data));
        
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getUserEventByUserIdFailed(errResponse))
    }
}