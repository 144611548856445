import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    user: null,
    events: [],
    message: null,
    error: null
}

const UserSlice = createSlice({
    name: 'Users',
    initialState,
    reducers: {
        getUserListStart: (state) => {
            state.isLoading = true;
        },
        getUserListSuccess: (state, action) => {
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false
        },
        getUserListFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },

        // Create User Slice
        createUserStart: (state) => {
            state.message = null;
            state.error = null;
            state.isLoading = true;
        },
        createUserSuccess: (state, action) => {
            state.user = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        createUserFailed: (state, action) => {
            state.error = action?.payload;
            state.message = null;
            state.isLoading = false;
        },

        // Get User By Id
        getUserByIdStart: (state) => {
            state.isLoading = true;
        },
        getUserByIdSuccess: (state, action) => {
            state.user = action?.payload?.data;
            state.message = null;
            state.isLoading = false;
        },
        getUserByIdFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },

        // Update User
        updateUserStart: (state) => {
            state.isLoading = true;
        },
        updateUserSuccess: (state, action) => {
            state.user = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        updateUserFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },

        // Delete User
        deleteUserStart: (state) => {
            state.isLoading = false;
        },
        deleteUserSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        deleteUserFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },
        
        // User events
        getUserEventByUserIdStart: (state) => {
            state.isLoading = true;
            state.message = null;
            state.error = null;
        },
        getUserEventByUserIdSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.events = action?.payload?.data;
            state.isLoading = false;
        },
        getUserEventByUserIdFailed: (state, action) => {
            state.error = action?.payload;
            state.message = null;
            state.isLoading = false;
        }
    }
});

export const {
    getUserListStart,
    getUserListSuccess,
    getUserListFailed,
    createUserStart,
    createUserSuccess,
    createUserFailed,
    getUserByIdStart,
    getUserByIdSuccess,
    getUserByIdFailed,
    updateUserStart,
    updateUserSuccess,
    updateUserFailed,
    deleteUserStart,
    deleteUserSuccess,
    deleteUserFailed,
    getUserEventByUserIdStart,
    getUserEventByUserIdSuccess,
    getUserEventByUserIdFailed
} = UserSlice?.actions;
export default UserSlice?.reducer;