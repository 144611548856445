import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    graph: [],
    card: [],
    events: [],
    eventDetails: [],
    activity: [],
    lastActivity: null,
    message: null,
    error: null
}

const DashboardSlice = createSlice({
    name: 'Dashboard',
    initialState,
    reducers: {
        // Dashboard Card
        getDashboardCardStart: (state) => {
            state.isLoading = true;
        },
        getDashboardCardSuccess: (state, action) => {
            state.card = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        getDashboardCardFailed: (state, action) => {
            state.error = action?.payload;
            state.card = [];
            state.isLoading = false;
        },

        // Dashboard Activity
        getDashboardActvityStart: (state) => {
            state.isLoading = true;
        },
        getDashboardActivitySuccess: (state, action) => {
            state.activity = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        getDashboardActivityFailed: (state, action) => {
            state.error = action?.payload?.data;
            state.activity = [];
            state.isLoading = false;
        },

        // Dashboard Last Activity
        getDashboardLastActvityStart: (state) => {
            state.isLoading = true;
        },
        getDashboardLastActivitySuccess: (state, action) => {
            state.lastActivity = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        getDashboardLastActivityFailed: (state, action) => {
            state.error = action?.payload;
            state.lastActivity = null;
            state.isLoading = false;
        },

        // Admin Dashboard Card
        getAdminDashboardCardStart: (state) => {
            state.isLoading = true;
            state.card = [];
            state.message = null;
            state.error = null;
        },
        getAdminDashboardCardSuccess: (state, action) => {
            state.card = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        getAdminDashboardCardFailed: (state, action) => {
            state.error = action?.payload;
            state.message = null;
            state.isLoading = false;
        },

        // Get Graph
        getDashboardGraphStart: (state) => {
            state.isLoading = false;
            state.graph = [];
            state.message = null;
            state.error = null;
        },
        getDashboardGraphSuccess: (state, action) => {
            state.graph = action?.payload?.data?.length ? action?.payload?.data.reverse() : [];
            state.message = action?.payload?.message;
            state.isLoading = false
        },
        getDashboardGraphFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        }
        
    }
});


export const {
    getDashboardCardStart,
    getDashboardCardSuccess,
    getDashboardCardFailed,
    getDashboardActvityStart,
    getDashboardActivitySuccess,
    getDashboardActivityFailed,
    getDashboardLastActvityStart,
    getDashboardLastActivitySuccess,
    getDashboardLastActivityFailed,
    getAdminDashboardCardStart,
    getAdminDashboardCardSuccess,
    getAdminDashboardCardFailed,
    getDashboardGraphStart,
    getDashboardGraphSuccess,
    getDashboardGraphFailed
} = DashboardSlice?.actions;
export default DashboardSlice?.reducer;
