import { put, call } from 'redux-saga/effects';

// Reducers
import { getCountryListSuccess, getCountryListFailed } from '../../slices/CountrySlice';

// Services
import { getCountryList } from '../../services/requests';

export function* getCountryListHandler(actions) {
    try {
        const response = yield call(getCountryList, actions?.payload);
        yield put(getCountryListSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getCountryListFailed(errResponse))
    }
}