import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    club: null,
    message: null,
    error: null
}

const ClubSlice = createSlice({
    name: 'Clubs',
    initialState,
    reducers: {
        getClubListStart: (state) => {
            state.isLoading = true;
        },
        getClubListSuccess: (state, action) => {
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false
        },
        getClubListFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },

        // Create Club Slice
        createClubStart: (state) => {
            state.isLoading = true;
        },
        createClubSuccess: (state, action) => {
            state.club = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        createClubFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },

        // Get Club By Id
        getClubByIdStart: (state) => {
            state.isLoading = true;
        },
        getClubByIdSuccess: (state, action) => {
            state.club = action?.payload?.data;
            state.message = null;
            state.isLoading = false;
        },
        getClubByIdFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },

        // Update Club
        updateClubStart: (state) => {
            state.isLoading = true;
        },
        updateClubSuccess: (state, action) => {
            state.club = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        updateClubFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },

        // Delete Club
        deleteClubStart: (state) => {
            state.isLoading = false;
        },
        deleteClubSuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        deleteClubFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },

        //PublicList
        getClubPublicListStart: (state) => {
            state.isLoading = true;
        },
        getClubPublicListSuccess: (state, action) => {
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false
        },
        getClubPublicListFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },
    }
});

export const {
    getClubListStart,
    getClubListSuccess,
    getClubListFailed,
    createClubStart,
    createClubSuccess,
    createClubFailed,
    getClubByIdStart,
    getClubByIdSuccess,
    getClubByIdFailed,
    updateClubStart,
    updateClubSuccess,
    updateClubFailed,
    deleteClubStart,
    deleteClubSuccess,
    deleteClubFailed,
    getClubPublicListStart,
    getClubPublicListSuccess,
    getClubPublicListFailed
} = ClubSlice?.actions;
export default ClubSlice?.reducer;