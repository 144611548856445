import React from 'react'
import { Knob } from 'primereact/knob';

function index({
    value = 0,
    onChange = null,
    min=0,
    max=100,
    step=1,
    strokeWidth=5,
    size=100,
    readOnly = true,
    rangeColor = '#bfbdbb',
    valueColor = '#eb6734'
}) {
  return (
    <Knob 
        value={value} 
        {...(onChange && { onChange })}
        min={min} 
        max={max}
        step={step}
        strokeWidth={strokeWidth}
        size={size}
        valueColor={valueColor}
        readOnly={readOnly}
        color={rangeColor}
    />
  )
}

export default index