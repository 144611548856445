import React, { useRef } from "react";
import { Menu } from 'primereact/menu';
import Button from "../Button";

const Menus = ({
    isButton = false,
    buttonLabel = '',
    dismissable=true,
    buttonIcon = null,
    buttonSize= 'small',
    popupAlignment='left',
    items = [],
    popup = false,
    className=''
}) => {
    const menuRef = useRef(null);

    const handleClick = (event) => {
        if (menuRef.current) {
            menuRef.current.toggle(event);
        } else {
            console.error("menuRef is not defined");
        }
    };

    return (
        <>
            <Menu 
                model={items}
                popup={popup}
                dismissable={dismissable}
                popupAlignment={popupAlignment}
                className={className}
                onClick={(e) => { }}
                ref={menuRef}
            />
            {
                isButton && <Button icon={buttonIcon} size={buttonSize} label={buttonLabel} onClick={handleClick} />
            }        
        </>
    );
}

export default Menus;
