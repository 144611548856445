import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    state: [],
    city: [],
    country: null,
    message: null,
    error: null
}

const CountrySlice = createSlice({
    name: 'Country',
    initialState,
    reducers: {
        getCountryListStart: (state) => {
            state.isLoading = true;
        },
        getCountryListSuccess: (state, action) => {
            state.data = action?.payload?.data;
            if (action?.payload?.data?.states?.length) {
                const states = action?.payload?.data?.states?.map((state) => {
                    return {
                        stateId: state?.id,
                        name: state?.name
                    }
                })
                state.state = states;
            }
            state.message = action?.payload?.message;
            state.isLoading = false
        },
        getCountryListFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },
    }
});

export const {
    getCountryListStart,
    getCountryListSuccess,
    getCountryListFailed
} = CountrySlice?.actions;
export default CountrySlice?.reducer;