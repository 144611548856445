// import React, { Suspense, lazy } from 'react';

// // Routers
// import { BrowserRouter, Routes, Route, Navigate,} from 'react-router-dom';

// // Layout
// import AuthLayout from '../feature/layouts/auth';
// import DashboardLayout from '../feature/layouts/Dashboard';

// // Routes
// import ProtectedRoutes from './ProtectedRoutes';

// // Pages
// import Login from '../pages/Auth/Login';
// import Register from '../pages/Auth/Register';
// import Dashboard from '../pages/Dashboard';
// import Event from '../pages/Event';
// import Club from '../pages/Club';
// import Users from '../pages/Users';
// import Confirmation from '../pages/Auth/Confirmation';
// import NewEvent from '../pages/Event/NewEvent';
// import EventDetails from '../views/Event/Event/EventDetails';
// import UserEventDetails from '../views/Event/Event/UserEvents/EventDetails/EventDetails';
// import ForgotPassword from '../pages/Auth/ForgotPassword'
// import ResetPassword from '../pages/Auth/ResetPassword';
// import Apps from '../pages/Apps';
// import AppConfirmation from '../views/Apps/App/Confirmations';
// import Settings from '../pages/Settings';
// import Admin from '../pages/Admin';
// import CreateAdmin from '../views/Admin/Admin/CreateAdmin';
// import UserDetails from '../views/Users/User/UserDetails';
// import UsersView from '../views/Users';
// import EmailNotification from '../pages/Auth/Register/EmailNotification';
// import Team from '../pages/Team';
// import Home from '../pages/Info/Home';
// import InfoLayout from '../feature/layouts/Info';
// import Store from '../pages/Info/Store';
// import PartnerWithUs from '../pages/Info/PartnerWithUs';
// import ContactUs from '../pages/Info/Contact';

// const Home = lazy(() => import('../pages/Info/Home'));
// const StoreInfo = lazy(() => import('../pages/Info/Store'));
// const PartnerWithUs = lazy(() => import('../pages/Info/PartnerWithUs'));
// const ContactUs = lazy(() => import('../pages/Info/Contact'));

// const Routers = () => {
//     return (
//         <BrowserRouter>
//             <Routes>
//                 <Route
//                     index
//                     element={<Navigate to="/home" replace/>} 
//                 />

//                 {/* UnProtected Routes */}
//                 {/* <Route path='/' element={<InfoLayout />}>
//                     <Route path='home' element={<Home />} />
//                     <Route path='store' element={<Store />} />
//                     <Route path='partner-with-us' element={<PartnerWithUs />} />
//                     <Route path='pledges' element={<Home />} />
//                     <Route path='contact-us' element={<ContactUs />} />
//                 </Route> */}

//                 <Suspense fallback={<div>Loading...</div>}>
//                     <Switch>
//                         <Route path='home' element={<Home />} />
//                         <Route path='store' element={<Store />} />
//                         <Route path='partner-with-us' element={<PartnerWithUs />} />
//                         {/* <Route path='pledges' element={<Home />} /> */}
//                         <Route path='contact-us' element={<ContactUs />} />
//                     </Switch>
//                 </Suspense>
//                 <Route path="/account" element={<AuthLayout />}>
//                     <Route path="login" element={<Login />} />
//                     <Route path="register" element={<Register />} />
//                     <Route path="forgot-password" element={<ForgotPassword />} />
//                     <Route path="reset-password" element={<ResetPassword />} />
//                     <Route path="email-notification" element={<EmailNotification />} />
//                 </Route>

//                 <Route path="confirmations" element={<Confirmation />} />   
//                 <Route path="/" element={<ProtectedRoutes><DashboardLayout /></ProtectedRoutes>}>
//                     <Route path='dashboard' element={<Dashboard />} />
//                     <Route path='club'>
//                         <Route index element={<Club />} />
//                         <Route path=':clubId/details' element={<UsersView />} />
//                     </Route>
//                     <Route path='team'>
//                         <Route index element={<Team />} />
//                         <Route path=':teamId/details' element={<UsersView />} />
//                     </Route>
//                     <Route path='users'>
//                         <Route index element={<Users />} />
//                         <Route path=':userId/details' element={<UserDetails/>} />
//                     </Route>
//                     <Route path='event'>
//                         <Route index element={<Event />} />
//                         <Route path='create' element={<NewEvent />} />
//                         <Route path=':eventId/edit' element={<NewEvent />} />
//                         <Route path=':eventId/details' element={<EventDetails />} />
//                         <Route path=':eventId/leaderboard' element={<UserEventDetails />} />
//                     </Route>
//                     <Route path='admin'>
//                         <Route index element={<Admin />} />
//                         <Route path='create' element={<CreateAdmin />} />
//                     </Route>
//                     <Route path='apps' >
//                         <Route index element={<Apps />} />
//                         <Route path=':appName/connect' element={<AppConfirmation />} />
//                     </Route>
//                     <Route path="/settings" element={<Settings/>}></Route>
                    
//                 </Route>
//             </Routes>
//         </BrowserRouter>
//     )
// }

// // https://www.strava.com/oauth/authorize?client_id=52278&redirect_uri=http://localhost:3000/apps/strava/connect&response_type=code&scope=read,read_all,profile:read_all,profile:write,activity:read,activity:read_all,activity:write


// export default Routers


import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Loader from '../components/Loader';
import Pledges from '../pages/Info/Pledges';

// Layouts
const AuthLayout = lazy(() => import('../feature/layouts/auth'));
const DashboardLayout = lazy(() => import('../feature/layouts/Dashboard'));
const InfoLayout = lazy(() => import('../feature/layouts/Info'));

// Protected Routes
const ProtectedRoutes = lazy(() => import('./ProtectedRoutes'));

// Pages
const Login = lazy(() => import('../pages/Auth/Login'));
const Register = lazy(() => import('../pages/Auth/Register'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Event = lazy(() => import('../pages/Event'));
const Club = lazy(() => import('../pages/Club'));
const Users = lazy(() => import('../pages/Users'));
const Confirmation = lazy(() => import('../pages/Auth/Confirmation'));
const NewEvent = lazy(() => import('../pages/Event/NewEvent'));
const EventDetails = lazy(() => import('../views/Event/Event/EventDetails'));
const UserEventDetails = lazy(() => import('../views/Event/Event/UserEvents/EventDetails/EventDetails'));
const ForgotPassword = lazy(() => import('../pages/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/Auth/ResetPassword'));
const Apps = lazy(() => import('../pages/Apps'));
const AppConfirmation = lazy(() => import('../views/Apps/App/Confirmations'));
const Settings = lazy(() => import('../pages/Settings'));
const Admin = lazy(() => import('../pages/Admin'));
const CreateAdmin = lazy(() => import('../views/Admin/Admin/CreateAdmin'));
const UserDetails = lazy(() => import('../views/Users/User/UserDetails'));
const UsersView = lazy(() => import('../views/Users'));
const EmailNotification = lazy(() => import('../pages/Auth/Register/EmailNotification'));
const Team = lazy(() => import('../pages/Team'));
const Home = lazy(() => import('../pages/Info/Home'));
const Store = lazy(() => import('../pages/Info/Store'));
const PartnerWithUs = lazy(() => import('../pages/Info/PartnerWithUs'));
const ContactUs = lazy(() => import('../pages/Info/Contact'));

const Routers = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loader isVisible={true} />}>
                <Routes>
                    {/* Default Redirect */}
                    <Route index element={<Navigate to="/home" replace />} />

                    {/* Public Routes */}
                    <Route path='/' element={<InfoLayout />}>
                        <Route path='home' element={<Home />} />
                        <Route path='store' element={<Store />} />
                        <Route path='partner-with-us' element={<PartnerWithUs />} />
                        <Route path='pledges' element={<Pledges />} />
                        <Route path='contact-us' element={<ContactUs />} />
                    </Route>

                    {/* Authentication Routes */}
                    <Route path="/account" element={<AuthLayout />}>
                        <Route path="login" element={<Login />} />
                        <Route path="register" element={<Register />} />
                        <Route path="forgot-password" element={<ForgotPassword />} />
                        <Route path="reset-password" element={<ResetPassword />} />
                        <Route path="email-notification" element={<EmailNotification />} />
                    </Route>

                    {/* Confirmation */}
                    <Route path="confirmations" element={<Confirmation />} />

                    {/* Protected Routes */}
                    <Route path="/" element={<ProtectedRoutes><DashboardLayout /></ProtectedRoutes>}>
                        <Route path='dashboard' element={<Dashboard />} />
                        <Route path='club'>
                            <Route index element={<Club />} />
                            <Route path=':clubId/details' element={<UsersView />} />
                        </Route>
                        <Route path='team'>
                            <Route index element={<Team />} />
                            <Route path=':teamId/details' element={<UsersView />} />
                        </Route>
                        <Route path='users'>
                            <Route index element={<Users />} />
                            <Route path=':userId/details' element={<UserDetails />} />
                        </Route>
                        <Route path='event'>
                            <Route index element={<Event />} />
                            <Route path='create' element={<NewEvent />} />
                            <Route path=':eventId/edit' element={<NewEvent />} />
                            <Route path=':eventId/details' element={<EventDetails />} />
                            <Route path=':eventId/leaderboard' element={<UserEventDetails />} />
                        </Route>
                        <Route path='admin'>
                            <Route index element={<Admin />} />
                            <Route path='create' element={<CreateAdmin />} />
                        </Route>
                        <Route path='apps'>
                            <Route index element={<Apps />} />
                            <Route path=':appName/connect' element={<AppConfirmation />} />
                        </Route>
                        <Route path="/settings" element={<Settings />} />
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};

export default Routers;
