import React from 'react';
import { Dropdown } from 'primereact/dropdown';


const Select = ({
    label=<></>,
    name='',
    value = '',
    onSelect = null,
    onBlur=null,
    onFilter=null,
    options=[],
    optionLabel='',
    placeholder='',
    className='',
    selectClassName='',
    checkMark=false,
    editable=false,
    showClear=true,
    loading=false,
    disabled=false,
    isRequired=false,
    isFilter=false,
    style={},
    labelClassName='',
    helperText=<></>
}) => {
  return (
    <div className={`flex flex-col gap-1 ${selectClassName}`}>
        <label htmlFor={label} className={labelClassName}>
          {label}
          <>
            {
              isRequired && <span className='text-red-600 text-xl'> *</span>
            }
          </>
        </label>
        <Dropdown
            name={name || label} 
            value={value} 
            onChange={(e) => onSelect(e.value)} 
            options={options} 
            optionLabel={optionLabel}
            placeholder={placeholder} 
            className={className}
            checkmark={checkMark}
            editable={editable}
            showClear={showClear}
            loading={loading}
            disabled={disabled}
            style={style}
            onBlur={onBlur}
            filter={isFilter}
            {...(isFilter && onFilter !== null) && { onFilter } }
        />
        {helperText}
    </div>
  )
}

export default Select