import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    club: null,
    message: null,
    error: null,
    uploadedImage: null,
    imageUploading: false,
    loggedAsUser: {
        isLoading: false,
        user: null,
        token: null,
        error: null,
        message: null
    }
}

const AuthSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        registerStart: (state) => {
            state.error = null;
            state.message = null;
            state.isLoading = true;
        },
        registerStartSuccess: (state, action) => {
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false
        },
        registerStartFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },
        confirmationStart: (state) => {
            state.isLoading = true;
        },
        confirmationStartSuccess: (state, action) => {
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false
        },
        confirmationStartFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },
        forgotPasswordStart: (state) => {
            state.error = null;
            state.message = null;
            state.isLoading = true;
        },
        forgotPasswordSuccess: (state, action) => {
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false
        },
        forgotPasswordFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },


        // Upload Image
        imageUploadStart: (state) => {
            state.imageUploading = true;
        },
        imageUploadSuccess: (state, action) => {
            state.message = null;
            state.error = null;
            state.uploadedImage = action?.payload?.data;
            state.imageUploading = false;
        },
        imageUploadFailure: (state, action) => {
            state.isLoading = false;
            state.message = null;
            state.error = action.payload;
            state.imageUploading = false;
        },
        clearUploadedImage: (state) => {
            state.uploadedImage = null;
            state.imageUploading = false;
        },

        // Clear the state
        clearState: (state) => {
            return {
                isLoading: false,
                user: null,
                token: null,
                error: null
            }
        },

        // Login
        loginStart: (state) => {
            state.message = null;
            state.error = null;
            state.isLoading = true;
        },
        loginSuccess: (state, action) => {
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        loginFailed: (state, action) => {
            state.error = action?.payload;
            state.message = null;
            state.isLoading = false;
        },

        // Update Password
        updatePasswordStart: (state) => {
            state.message = null;
            state.error = null;
            state.isLoading = true;
        },
        updatePasswordSuccess: (state, action) => {
            state.data = action?.payload?.data || null;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        updatePasswordFailed: (state, action) => {
            state.error = action?.payload;
            state.message = null;
            state.isLoading = false;
        },

        // Get user Profile
        getUserProfileStart: (state) => {
            state.message = null;
            state.error = null;
            state.isLoading = true
        },
        getUserProfileSuccess: (state, action) => {
            state.data = action?.payload?.data || null;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        getUserProfileFailed: (state, action) => {
            state.error = action?.payload;
            state.message = null;
            state.isLoading = false;
        },

        // Update User Profile
        updateProfileStart: (state) => {
            state.message = null;
            state.error = null;
            state.isLoading = true;
        },
        updateProfileSuccess: (state, action) => {
            state.data = action?.payload?.data || null;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        updateProfileFailed: (state, action) => {
            state.error = action?.payload;
            state.message = null;
            state.isLoading = false;
        }
    }
});


export const {
    registerStart,
    registerStartSuccess,
    registerStartFailed,
    confirmationStart,
    confirmationStartSuccess,
    confirmationStartFailed,
    forgotPasswordStart,
    forgotPasswordSuccess,
    forgotPasswordFailed,
    imageUploadStart,
    imageUploadSuccess,
    imageUploadFailure,
    clearUploadedImage,
    clearState,
    loginStart,
    loginSuccess,
    loginFailed,
    updatePasswordStart,
    updatePasswordSuccess,
    updatePasswordFailed,
    getUserProfileStart,
    getUserProfileSuccess,
    getUserProfileFailed,
    updateProfileStart,
    updateProfileSuccess,
    updateProfileFailed
} = AuthSlice?.actions;
export default AuthSlice?.reducer;