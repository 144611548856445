import React, { useState, useEffect, useRef } from "react";

// Router
import { useNavigate } from "react-router-dom";

// Common Components
import Menu from "../../../components/Menu";
import Modal from "../../../components/Modal";
import Select from '../../../components/Select';
import Button from "../../../components/Button";
import Toast from "../../../components/Toast";

// ICONS
import { ICONS } from "../../../constant/types";

// Modal
import DeleteEvent from "../../../feature/Modals/Event/DeleteEvent";
import { useDispatch, useSelector } from "react-redux";
import { TABLE, toastMessageBuilder, USER_ROLES } from "../../../utils/utils";
import { getUserListStart } from "../../../store/slices/UserSlice";
import { addUserToEventStart } from "../../../store/slices/EventSlice";




const EventActions = ({ event }) => {
    const loggedInUser = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate();
    const [action, setAction] = useState({
        edit: false,
        delete: false,
        toggle: false,
        user: false
    });

    const handleMenuItemClick = (e, operation) => {
        if (operation === 'edit') {
            navigate(`${event?.id}/edit`)
        }
        if (operation === 'view') {
            navigate(`${event?.id}/details`)
        }
        if (operation === 'stats') {
            navigate(`${event?.id}/leaderboard`)
        }

        if (action?.hasOwnProperty(operation)) {
            setAction((action) => ({ ...action, [operation]: true }))
        }
    }

    const getMenuList = () => {
        try {
            const isAdmin = loggedInUser?.role === USER_ROLES[2];
            let menus = [
                {
                    name: 'view',
                    label: <span className="font-semibold text-sm">View</span>,
                    icon: 'pi pi-eye text-xs',
                    command: (e) => handleMenuItemClick(e, 'view')
                },
                {
                    name: 'stats',
                    label: <span className="font-semibold text-sm">Stats</span>,
                    icon: 'pi pi-chart-bar text-xs',
                    command: (e) => handleMenuItemClick(e, 'stats')
                },
                {
                    name: 'user',
                    label: <span className="font-semibold text-sm">User</span>,
                    icon: 'pi pi-plus text-xs',
                    command: (e) => handleMenuItemClick(e, 'user')
                },
                {
                    name: 'edit',
                    label: <span className="font-semibold text-sm">Edit</span>,
                    icon: 'pi pi-pencil text-xs',
                    command: (e) => handleMenuItemClick(e, 'edit')
                },
                {
                    name: 'delete',
                    label: <span className="font-semibold text-sm">Delete</span>,
                    icon: 'pi pi-trash text-xs',
                    command: (e) => handleMenuItemClick(e, 'delete')
                }
            ];
            if (!isAdmin) {
                menus = menus?.filter((menu) => menu?.name === 'stats');
                return menus;
            }
            return menus;
        } catch(err){

        }
    }

    return (
        <>
            <Menu 
                popup={true}
                dismissable={false}
                isButton={true}
                buttonLabel={<>{ ICONS?.THREE_DOT('h-4 w-4') }</>}
                buttonSize={'small'}
                className='w-28'
                // buttonIcon={ICONS?.ADMIN('h-4 w-4 m-2')}
                items={getMenuList()
                    // [
                    //     {
                    //         label: <span className="font-semibold text-sm">View</span>,
                    //         icon: 'pi pi-eye text-xs',
                    //         command: (e) => handleMenuItemClick(e, 'view')
                    //     },
                    //     {
                    //         label: <span className="font-semibold text-sm">Stats</span>,
                    //         icon: 'pi pi-chart-bar text-xs',
                    //         command: (e) => handleMenuItemClick(e, 'stats')
                    //     },
                    //     {
                    //         label: <span className="font-semibold text-sm">User</span>,
                    //         icon: 'pi pi-plus text-xs',
                    //         command: (e) => handleMenuItemClick(e, 'user')
                    //     },
                    //     {
                    //         label: <span className="font-semibold text-sm">Edit</span>,
                    //         icon: 'pi pi-pencil text-xs',
                    //         command: (e) => handleMenuItemClick(e, 'edit')
                    //     },
                    //     {
                    //         label: <span className="font-semibold text-sm">Delete</span>,
                    //         icon: 'pi pi-trash text-xs',
                    //         command: (e) => handleMenuItemClick(e, 'delete')
                    //     }
                    // ]
                }
            />

            {/* Modals */}
            { 
                action?.user && <Modal
                    visible={action?.user}
                    header={<span className="font-normal">Add Users to Event (<span className="font-semibold">{ event?.name }</span>)</span>}
                    body={<AddUser event={event} onClose={() => { setAction((action) => ({ ...action, user: false })) }}/>}
                    style={{width: '40vw', height: '40vh'}}
                    resizable={true}
                    onClose={() => { setAction((action) => ({ ...action, user: false })) }}
                />
            }
            {
                action?.delete && <Modal 
                    visible={action?.delete}
                    header={<span className="text-xl font-normal pi pi-trash"> Delete Event - {event?.name}</span>}
                    body={<DeleteEvent event={event} onClose={() => { setAction((action) => ({ ...action, delete: false }))}} />}
                    onClose={() => { setAction((action) => ({ ...action, delete: false })) }}
                /> 
            }
        </>
    )
}


const AddUser = ({ event, onClose }) => {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state?.user);
    const events = useSelector((state) => state?.event);
    const toasts = useRef(null);
    const [params, setParams] = useState({
        page: 1,
        count: 5,
        search: '',
        isActive: true,
        isDeleted: false,
    });
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        if (params?.search) {
            const delayDebounceFn = setTimeout(() => {
                dispatch(getUserListStart(params));
            }, 300)
            return () => clearTimeout(delayDebounceFn);
        } else {
            fetchUserList(params)
        }
    }, [dispatch, params])
    
    useEffect(() => {
        if (events?.addEventUser) {
            const timer = setTimeout(() => {
                toasts?.current?.show(toastMessageBuilder({
                    title: 'Event Registrations',
                    message: events?.addEventUser,
                }))
                onClose();
            }, 1000);
        }
    }, [events?.addEventUser])

    useEffect(() => {
        if (events?.error) {
            const timer = setTimeout(() => {
                toasts?.current?.show(toastMessageBuilder({
                    title: 'Event Registrations',
                    message: events?.error,
                    severity: 'danger'
                }))
            }, 1000);
        }
    }, [events?.error])
    
    const fetchUserList = () => {
        try {
            dispatch(getUserListStart())
        } catch(err) {
            throw err;
        }
    }
    
    const onSearchHandler = (e) => {
        try {
            setParams((params) => ({
            ...params,
            search: e?.filter
            }))
        } catch(err) {
            throw err;
        }
    }
    
    const onSelectHandler = (id) => {
        try {
            if (!id) {
                return;
            }
            const user = data?.users?.find((user) => user?._id === id);
            setSelectedUser(user);
        } catch(err) {
            throw err;
        }
    }

    const onAddUserHandler = () => {
        try {
            dispatch(addUserToEventStart({ eventId: event?.id, userId: selectedUser?._id }))
        } catch(err) {
            throw err;
        }
    };
    return (
        <>
            <Toast ref={toasts}/>
            { data?.users?.length ? <>
            <div className="flex justify-between gap-12">
                <div className="w-full">
                    <Select 
                        placeholder={'Choose the users'}
                        optionLabel={'name'}
                        options={TABLE?.USER?.FORMATED(data?.users)}
                        onSelect={(e) => { onSelectHandler(e) }}
                        isFilter={true}
                        value={selectedUser?._id}
                        onFilter={(e) => { onSearchHandler(e) }}
                    />
                </div>
                <div className="">
                    <Button 
                        size="small" 
                        className="h-12"
                        label={'Add User'} 
                        onClick={() => { onAddUserHandler() }}
                    />
                </div>
            </div>
            </> : <></>}

            <div>
                { Object.keys(selectedUser || {})?.length ? <div className="p-4">
                    <div className="flex justify-start gap-4">
                        <div className="my-8">
                            <img src={selectedUser?.profilePicture} className="w-20 h-20 rounded-xl" />
                        </div>
                        <div className="flex flex-col items-center my-8">
                            <div>
                                <span className="font-semibold text-xl">{ selectedUser?.firstName || '' } { selectedUser?.lastName || '' }</span>
                                <p>{ selectedUser?.email }</p>
                            </div>
                        </div>
                    </div>
                </div> : <></>}
            </div>
        </>
    )
}

export default EventActions;