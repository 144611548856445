import { combineReducers } from '@reduxjs/toolkit';

// Slice
import ClubSlice from './ClubSlice';
import CountrySlice from './CountrySlice'
import UserSlice from './UserSlice';
import AuthSlice from './AuthSlice';
import EventSlice from './EventSlice';
import AppSlice from './AppSlice';
import DashboardSlice from './DashboardSlice';
import ActivitySlice from './ActivitySlice';
import TeamSlice from './TeamSlice';

const rootReducer = combineReducers({
    club: ClubSlice,
    country: CountrySlice,
    user: UserSlice,
    auth: AuthSlice,
    event: EventSlice,
    app: AppSlice,
    dashboard: DashboardSlice,
    activity: ActivitySlice,
    team: TeamSlice,
})

export default rootReducer;