import * as Yup from 'yup';
import { Regex } from '../constant/types';

export const VALIDATION = {
    CLUB: {
        CREATE: Yup.object({
          name: Yup.string()
            .required('Name is required'),
          descriptions: Yup.string()
            .required('Descriptions are required'),
          email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
          phone: Yup.string()
            .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
            .required('Phone number is required'),
          address: Yup.object({
            street: Yup.string()
              .required('Street is required'),
            city: Yup.string()
              .required('City is required'),
            state: Yup.string()
              .required('State is required'),
            country: Yup.string()
              .required('Country is required'),
            pincode: Yup.string()
              .matches(/^\d{5,6}$/, 'Pincode must be 5 or 6 digits')
              .required('Pincode is required')
          })
        })
    },
    AUTHENTICATION: {
      REGISTER : Yup.object({
        firstName: Yup.string()
          .max(50, 'Maximum limit exceeds')
          .required('FirstName is Required'),
        lastName: Yup.string()
          .max(50, 'Maximum limit exceeds')
          .required('Required'),
        email: Yup.string()
          .matches(Regex.email,'Invalid email address'
          )
          .required('Email is Required'),
        phone: Yup.string()
          .matches(Regex.phoneNumber,'Invalid mobile number')
          .required('Phone Number is Required'),
        password: Yup.string()
            .matches(Regex.password,
            'Must contain 8 characters, at least one letter and one number'
          )
          .required('Password is Required'),
        team: Yup.string().required('Team is Required'),
        age: Yup.number().required('Age is Required'),
        gender: Yup.string().required('Gender is Required'),
        bloodGroup: Yup.string().required('Blood Group Is Required'),
        agreedTermsAndCondition: Yup.boolean().oneOf([true], 'Kindly agree to the terms and conditions').required('Kindly agree the terms and condition'),
        confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password Required'),
      }),
      LOGIN: Yup?.object({
        email: Yup.string()
          .email('Invalid email address')
          .required('Email is required'),
        password: Yup.string()
          .matches(Regex.password,
          'Must contain 8 characters, at least one letter and one number')
          .required('Password is Required'),
      })
    },
    EVENT: {
      CREATE: Yup.object({
        name: Yup.string()
          .required('Name is required'),
        descriptions: Yup.string()
          .required('Descriptions are required'),
        logo: Yup.string()
          .required('Logo is Required'),
        coverPicture: Yup.string()
          .required('Cover Picture is required'),
        startDate: Yup.date()
          .required('Event Start Date is required'),
        endDate: Yup.date()
          .required('Event Date Date is required'),
        distance: Yup.number()
          .required('distance is required'),
        unit: Yup.string()
          .required('Unit is Required'),
        registrationFee: Yup?.number()
          .required('Registration Fee is Required')
      })
    },
    USER: {
      CREATE: Yup.object({
        firstName: Yup.string()
          .max(50, 'Maximum limit exceeds')
          .required('First Name is Required'),
        lastName: Yup.string()
          .max(50, 'Maximum limit exceeds')
          .required('Last Name Required'),
        email: Yup.string()
          .matches(Regex.email,'Invalid email address'
          )
          .required('Email is Required'),
        phone: Yup.string()
          .matches(Regex.phoneNumber,'Invalid mobile number')
          .required('Phone Number is Required'),
        password: Yup.string()
            .matches(Regex.password,
            'Must contain 8 characters, at least one letter and one number'
          )
          .required('Password is Required'),
        confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password Required'),
      }),
    },
    PROFILE: {
      UPDATE: Yup.object({
        firstName: Yup.string()
          .max(50, 'Maximum limit exceeds')
          .required('First Name is Required'),
        lastName: Yup.string()
          .max(50, 'Maximum limit exceeds')
          .required('Last Name Required'),
        age: Yup.number().required('Age is Required'),
        gender: Yup.string().required('Gender is Required'),
        bloodGroup: Yup.string().required('Blood Group is Required'),
        phone: Yup.string()
            .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
            .required('Phone number is required'),
        address: Yup.object({
          street: Yup.string()
            .required('Street is required'),
          city: Yup.string()
            .required('City is required'),
          state: Yup.string()
            .required('State is required'),
          country: Yup.string()
            .required('Country is required'),
          pincode: Yup.string()
            .matches(/^\d{5,6}$/, 'Pincode must be 5 or 6 digits')
            .required('Pincode is required')
        })
      })

    },
    TEAM: {
      CREATE: Yup.object({
        name: Yup.string().required('Team name is required'),
        descriptions: Yup.string().required('Team descriptions are required')
      })
    }
}