import React, { useEffect, useState, useRef } from 'react'

// Components
import InputField from '../../../components/InputField'
import Button from '../../../components/Button'
import Mention from '../../../components/Mention';
import Toast from '../../../components/Toast';
import Upload from '../../../components/Upload';
import Loader from '../../../components/Loader';

import { useFormik } from 'formik'
import { VALIDATION } from '../../../utils/validation'
import { useDispatch, useSelector } from 'react-redux';
import { getUserListStart } from '../../../store/slices/UserSlice';
import { TABLE, toastMessageBuilder } from '../../../utils/utils';
import { ICONS } from '../../../constant/types';
import { createTeamStart, getTeamByIdStart, updateTeamStart } from '../../../store/slices/TeamSlice';
import { clearUploadedImage, imageUploadStart } from '../../../store/slices/AuthSlice';

const CreateTeam = ({ teamId, onClose }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state?.user);
  const teams = useSelector((state) => state?.team);
  const { uploadedImage, imageUploading } = useSelector((state) => state?.auth);

  const [team, setTeam] = useState({
    name: '',
    descriptions: '',
    logo: 'https://ik.imagekit.io/indiadev2024/APPS/LOGOS/Default_Team_Logo?updatedAt=1726993762176',
    users: [],
  });
  const toasts = useRef(null);
  const [value, setValue] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userParams, setUserParams] = useState({
    page: 1,
    count: 50,
    search: '',
    isActive: true,
    isDeleted: false,
  })
  const [isTeamCreated, setIsTeamCreated] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [profilePictureUploaded, setProfilePictureUploaded] = useState(false);
  const teamFormik = useFormik({
    initialValues: team,
    validationSchema: VALIDATION?.TEAM?.CREATE
  })
  const { values, handleChange, handleBlur, touched, errors } = teamFormik;

  useEffect(() => {
    if (teams?.error === null && teams?.message && isTeamCreated) {
      toasts?.current?.show(
        toastMessageBuilder({
          title: 'Team',
          message: teams?.message
        })
      )
      onClose();
    }
  }, [teams?.error, teams?.message])

  useEffect(() => {
    if (teamId) {
      return;
    }
    if (userParams?.search) {
      const delayDebounceFn = setTimeout(() => {
          dispatch(getUserListStart(userParams));
      }, 300)
      return () => clearTimeout(delayDebounceFn);
    } else {
      fetchUserList(userParams)
    }
  }, [dispatch, userParams])

  useEffect(() => {
    if (teamId) {
      dispatch(getTeamByIdStart({ teamId }))
    }
  }, [teamId])

  useEffect(() => {
    if (teamId && Object.keys(teams?.team || {})?.length) {
      setTeam((team) => ({
        ...team,
        ...teams?.team
      }))
      teamFormik?.setValues(teams?.team);
    }
  }, [teams?.team])
  
  useEffect(() => {
    if (uploadedImage?.documentURL && profilePictureUploaded) {
      teamFormik?.setFieldValue('logo', uploadedImage?.documentURL);
      setProfilePictureUploaded(false);
      dispatch(clearUploadedImage());
    }
  }, [uploadedImage?.documentURL])

  const fetchUserList = async (params) => {
    try {
      dispatch(getUserListStart(params))
    } catch(err) {
      throw err;
    }
  }

  const onUserSearchHandler = (e) => {
    try {
      setUserParams((params) => ({
        ...params,
        search: e?.query
      }))
    } catch(err) {
      throw err;
    }
  }

  const itemTemplate = (user) => {
    return (
        <div className="flex flex-row">
            <img alt={user.name} src={user?.profilePicture} className='w-12 h-12 rounded' />
            <span className="flex flex-col ml-2">
                <span className='text-sm'>{user.name}</span>
                <span className='text-xs text-gray-400'>@{user.email}</span>
            </span>
        </div>
    );
  }

  const onSelectUser = (user) => {
    try {
      const users = [...selectedUsers];
      const isUserExist = users?.findIndex((existedUser) => existedUser?.id === user?.id);
      if (isUserExist !== -1) {
        setValue('');
        return;
      }
      users?.push(user);
      setSelectedUsers(users);
      setValue('');
    } catch(err) {
      throw err;
    }
  }

  const removeSelectedUser = (userId) => {
    try {
      if (!userId) {
        return;
      }
      const updatedUsers = selectedUsers?.filter((user) => user?.id !== userId);
      setSelectedUsers([...updatedUsers]);
    } catch(err) {
      throw err;
    }
  }

  const profilePictureUpload = (file, previewURL) => {
    dispatch(imageUploadStart(file))
    setProfilePictureUploaded(true);
  }

  const onTeamCreateHandler = () => {
    try {
      const teamPayload = {
        name: values?.name,
        descriptions: values?.descriptions,
        logo: values?.logo
      }
      if (addUser && !teamId) {
        teamPayload['users'] = selectedUsers?.map((user) => user?.id);
      }
      if (teamId) {
        teamPayload['teamId'] = teamId
        dispatch(updateTeamStart(teamPayload))
      } else {
        dispatch(createTeamStart(teamPayload))
      }
      setIsTeamCreated(true);
    } catch(err) {
      throw err;
    }
  }

  return (
    <>
      <Toast ref={toasts}/>
      <Loader isVisible={imageUploading} />
      <div className='flex flex-col gap-8'>
        <InputField 
          name={'name'} 
          label={'Name'} 
          isRequired={true}
          value={values?.name}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder='Enter team name'
          className={`${touched?.name && errors?.name ? 'border-2 border-red-600' : ''}`}
          helperText={touched?.name && errors?.name ? <span className="text-sm text-red-700">{ errors?.name }</span> : <></>}
        />
        <div className='flex justify-between w-full'>
          <div className='w-3/4'>
            <InputField 
              name={'descriptions'} 
              type={'textarea'} 
              label={'Descriptions'} 
              rows={8}
              isRequired={true}
              value={values?.descriptions}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder='Enter team descriptions'
              className={`${touched?.descriptions && errors?.descriptions ? 'border-2 border-red-600' : ''}`}
              helperText={touched?.descriptions && errors?.descriptions ? <span className="text-sm text-red-700">{ errors?.descriptions }</span> : <></>}
            />
          </div>
          <div className='w-1/4'>
            <div className='flex flex-col items-center'>
              { values?.logo ? <img className='w-32 h-32 border border-solid object-cover shadow-xl rounded-lg mt-8' src={values?.logo} /> : <></>}
              <Upload buttonLabel='Upload Team Logo' labelClassName='font-semibold' onUpload={(file, previewURL) => { profilePictureUpload(file, previewURL) }} />
            </div>
            
          </div>
        </div>
        
        <div className={`flex ${teamId ? 'justify-end' : 'justify-between'}`}>
          { !teamId && <Button label={`${addUser ? 'Disable Add User': 'Add User'}`} size={'small'} onClick={() => { setAddUser(!addUser)}} className='bg-black outline-none border-none' /> }
          <Button label={`${teamId ? 'Update Team': 'Create Team'}`} size={'small'} className='bg-black outline-none border-none' disabled={!values?.name || !values?.descriptions || !values?.logo} onClick={() => {onTeamCreateHandler()}}/>
        </div>
        {addUser && <>
          <div>
            <div className='grid grid-cols-4 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-8'>
              {
                selectedUsers?.map((user) => (
                  <div 
                    className='p-2 border-1 border-solid shadow-md rounded-xl'
                  >
                    <div className="flex flex-row">
                      <img alt={user.name} src={user?.profilePicture} className='w-12 h-12 rounded-full' />
                      <div className="flex flex-col ml-2">
                          <span className='text-xs'>{user.name}</span>
                      </div>
                    </div>
                    <div className='flex justify-end'>
                      <span onClick={() => {removeSelectedUser(user?.id)}}>{ ICONS?.DELETE('text-red-500 cursor-pointer h-5 w-5 font-semibold')}</span>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className='w-full'>
            <Mention 
              label='Choose Users' 
              className={'w-full'} 
              value={value}
              onChange={(e) => { setValue(e?.target?.value )}}
              placeholder='Enter @ to choose user' 
              rows={10}
              suggestions={TABLE?.USER?.FORMATED(data?.users)}
              onSearch={(e) => { onUserSearchHandler(e) }}
              itemTemplate={itemTemplate}
              field={'name'}
              onSelect={(user) => {onSelectUser(user)}}
            />
          </div>
        </> }
      </div>
    </>
  )
}

export default CreateTeam