import { put, call } from 'redux-saga/effects';


// Reducers
import { getClubListSuccess, getClubListFailed, createClubSuccess, createClubFailed, getClubListStart, getClubByIdSuccess, updateClubFailed, updateClubSuccess, deleteClubSuccess, deleteClubFailed,getClubPublicListSuccess, getClubPublicListFailed } from '../../slices/ClubSlice';

// Services
import { createClub, deleteClub, getClubById, getClubList, updateClub, getClubPublicList, getClubLeaderboard } from '../../services/requests';
import { getClubLeaderboardFailed, getClubLeaderboardSuccess } from '../../slices/TeamSlice';

export function* getClubListHandler(actions) {
    try {
        const response = yield call(getClubList, actions?.payload);
        yield put(getClubListSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getClubListFailed(errResponse))
    }
}

export function* createClubHandler(actions) {
    try {
        const response = yield call(createClub, actions?.payload);
        yield put(createClubSuccess(response?.data));
        yield put(getClubListStart({
            page: 1,
            count: 10,
            search: '',
            isActive: true,
            isDeleted: false
        }))
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(createClubFailed(errResponse))
    }
}

export function* getClubByIdHandler(actions) {
    try {
        const response = yield call(getClubById, actions?.payload);
        yield put(getClubByIdSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(createClubFailed(errResponse))
    }
}

export function* updateClubHandler(actions) {
    try {
        const response = yield call(updateClub, actions?.payload);
        yield put(updateClubSuccess(response?.data));
        yield put(getClubListStart({
            page: 1,
            count: 5,
            search: '',
            isActive: true,
            isDeleted: false
        }));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(updateClubFailed(errResponse))
    }
}

export function* deleteClubHandler(actions) {
    try {
        const response = yield call(deleteClub, actions?.payload);
        yield put(deleteClubSuccess(response?.data));
        yield put(getClubListStart({
            page: 1,
            count: 5,
            search: '',
            isActive: true,
            isDeleted: false
        }));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(deleteClubFailed(errResponse))
    }
}

export function* getClubPublicListHandler(actions) {
    try {
        const response = yield call(getClubPublicList, actions?.payload);
        yield put(getClubPublicListSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getClubPublicListFailed(errResponse))
    }
}

export function* getClubLeaderboardHandler(actions) {
    try {
        const response = yield call(getClubLeaderboard, actions?.payload);
        yield put(getClubLeaderboardSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getClubLeaderboardFailed(errResponse))
    }
}
