import React, { useState } from "react";

// Common Components
import Menu from "../../../components/Menu";
import Modal from "../../../components/Modal";

// ICONS
import { ICONS } from "../../../constant/types";

// Modal
import CreateTeam from '../../../feature/Modals/Team/CreateTeam';
import DeleteTeam from '../../../feature/Modals/Team/DeleteTeam';

import { useNavigate } from "react-router-dom";
import AddUserToTeam from "../../../feature/Modals/Team/AddUserToTeam";


const TeamActions = ({ team }) => {
    const [action, setAction] = useState({
        edit: false,
        delete: false,
        toggle: false,
        addUser: false
    });
    const navigate = useNavigate();

    const handleMenuItemClick = (e, operation) => {
        if (operation === 'view') {
            navigate(`${team?.id}/details`)
        }
        if (action?.hasOwnProperty(operation)) {
            setAction((action) => ({ ...action, [operation]: true }))
        }
    }
    return (
        <>
            <Menu 
                popup={true}
                dismissable={false}
                isButton={true}
                buttonLabel={<>{ ICONS?.THREE_DOT('h-4 w-4') }</>}
                buttonSize={'small'}
                className='w-28'
                // buttonIcon={ICONS?.ADMIN('h-4 w-4 m-2')}
                items={[
                    {
                        label: <span className="font-semibold text-sm">View</span>,
                        icon: 'pi pi-eye text-xs',
                        command: (e) => handleMenuItemClick(e, 'view')
                    },
                    {
                        label: <span className="font-semibold text-sm">Add User</span>,
                        icon: 'pi pi-users text-xs',
                        command: (e) => handleMenuItemClick(e, 'addUser')
                    },
                    {
                        label: <span className="font-semibold text-sm">Edit</span>,
                        icon: 'pi pi-pencil text-xs',
                        command: (e) => handleMenuItemClick(e, 'edit')
                    },
                    {
                        label: <span className="font-semibold text-sm">Delete</span>,
                        icon: 'pi pi-trash text-xs',
                        command: (e) => handleMenuItemClick(e, 'delete')
                    }
                ]}
            />

            {/* Modals */}
            { 
                action?.edit && <Modal
                    visible={action?.edit}
                    header={<span className="text-xl font-normal">Update Team Information</span>}
                    body={<CreateTeam teamId={team?.id} onClose={() => { setAction((action) => ({ ...action, edit: false })) }}/>}
                    style={{width: '50vw'}}
                    resizable={true}
                    onClose={() => { setAction((action) => ({ ...action, edit: false })) }}
                />
            }
            {
                action?.delete && <Modal 
                    visible={action?.delete}
                    header={<span className="text-xl font-bold pi pi-trash"> Delete Team - {team?.name}</span>}
                    body={<DeleteTeam team={team} onClose={() => { setAction((action) => ({ ...action, delete: false }))}} />}
                    onClose={() => { setAction((action) => ({ ...action, delete: false })) }}
                /> 
            }
            {
                action?.addUser && <Modal 
                    visible={action?.addUser}
                    header={<span className="text-xl font-normal">Add User to <b>{team?.name}</b></span>}
                    body={<AddUserToTeam team={team} onClose={(() => { setAction((action) => ({ ...action, addUser: false })) })} />}
                    onClose={() => { setAction((action) => ({ ...action, addUser: false })) }}
                />
            }
        </>
    )
}

export default TeamActions;