import createSagaMiddleware from "@redux-saga/core";
import { configureStore } from "@reduxjs/toolkit";



// referrence
// https://codesandbox.io/s/redux-toolkit-and-redux-saga-mfetp?file=/src/saga.js

// Saga
import { watcherSaga } from "./saga";


// Reducers
import rootReducer from "./slices";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
}
  // middleware: [sagaMiddleware],
);

sagaMiddleware.run(watcherSaga)

export default store;