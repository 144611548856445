import React from 'react';

// Routers
import Routers from './routes';

const App = () => {
  return (
    <Routers />
  );
};

export default App;
