import React from 'react'

// Common Components
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';

// Icons
import { ICONS } from '../../../constant/types';

// Reducers
import { useDispatch, useSelector } from 'react-redux'
import { deleteTeamStart } from '../../../store/slices/TeamSlice';


const DeleteTeam = ({ team, onClose }) => {

  const dispatch = useDispatch();
  const teams = useSelector((state) => state?.team);

  const onDeleteHandler = () => {
    try {
      dispatch(deleteTeamStart({ teamId: team?.id }))
      onClose();
    } catch(err) {
      throw err;
    }
  }

  return (
    <>
      <Loader isVisible={team?.isLoading} />
      <div className='flex flex-col justify-center items-center'>
        <p className='font-bold text-xl'>Are you sure ?</p>
        <p>Do you want to delete this <span className='font-semibold'>{team?.name}</span> Team</p>
        <div className='flex justify-between gap-4 my-8'>
            <Button 
                label='Delete' 
                className='bg-red-700 border-none' 
                icon={ICONS?.DELETE('h-6 w-6 mx-1')}
                onClick={() => { onDeleteHandler() }} 
            />
            <Button 
                label='Cancel' 
                className='border-none' 
                icon={ICONS?.CANCEL('h-6 w-6 mx-1')} 
                onClick={() => { onClose() }}
            />
        </div>
      </div>
    </>
  )
}

export default DeleteTeam