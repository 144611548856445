import React from 'react'

import { Mention } from 'primereact/mention';

const index = ({
    name = '',
    value = '',
    placeholder = '',
    label = '',
    isRequired = false,
    rows=5,
    cols=100,
    className = '',
    field = '',
    fieldClassName = '',
    labelClassName = '',
    inputStyle={width: '100%', boxShadow: 'none'},
    onChange = null,
    onBlur = null,
    onSearch = null,
    itemTemplate = null,
    onSelect = null,
    suggestions = [],
}) => {
  return (
    <div className={`flex flex-col gap-2 ${fieldClassName}`}>
        {
            label && <label className={labelClassName} htmlFor={name}>{ label } <>{ isRequired && <span className='text-red-600 text-xl'> *</span>}</></label>
        }
        <Mention
            className={className}
            name={name}
            value={value}
            rows={rows}
            cols={cols}
            suggestions={suggestions}
            placeholder={placeholder}
            inputStyle={inputStyle}
            field={field}
            onSelect={({ suggestion }) => { onSelect(suggestion) }}
            {...(itemTemplate !== null && { itemTemplate })}
            {...(onChange !== null && { onChange })}
            {...(onBlur !== null && { onBlur })}
            {...(onSearch !== null && { onSearch })}  
        /> 
    </div>
    
  )
}

export default index