import { takeEvery } from "redux-saga/effects";


//RegisterHandler
import { imageUploadHandler, registerHandler , confirmationHandler, loginHandler, forgotPasswordHandler, updatePasswordHandler, getProfileHandler, updateProfileHandler } from "./handlers/auth.handlerSaga";


import { confirmationStart, forgotPasswordStart, getUserProfileStart, imageUploadStart, loginStart, registerStart, updatePasswordStart, updateProfileStart } from "../slices/AuthSlice";
import { createClubHandler, deleteClubHandler, getClubByIdHandler, getClubListHandler, updateClubHandler,getClubPublicListHandler, getClubLeaderboardHandler } from "./handlers/club.handlerSaga";
import { getUserListHandler, createUserHandler, getUserByIdHandler, updateUserHandler, deleteUserHandler, getUserEventHandler } from "./handlers/user.handlerSaga";
import { getCountryListHandler } from "./handlers/country.handlerSaga";
import { getEventListHandler, createEventHandler, getEventByIdHandler, updateEventHandler, deleteEventHandler, createOrderHandler, registerEventHandler, eventLeaderboardHandler, eventEventActivityHandler, addUserToEventHandler, getEventUsersHandler, completeEventHandler, removeUserFromEventHandler } from "./handlers/event.handlerSaga";
import { connectToAppHandler, disconnectAppHandler, getAppListHandler, syncActivity, syncActivityHandler } from "./handlers/app.handlerSaga";

// Club Slice
import { createClubStart, deleteClubStart, getClubByIdStart, getClubListStart, updateClubStart, getClubPublicListStart } from "../slices/ClubSlice";
import { getCountryListStart } from "../slices/CountrySlice";

import { getUserListStart, createUserStart, getUserByIdStart, updateUserStart, deleteUserStart, getUserEventByUserIdStart } from "../slices/UserSlice";
import { getEventListStart, createEventStart, getEventByIdStart, updateEventStart, deleteEventStart, createOrderStart, registerToEventStart, getLeaderboardStart, getActivityStart, addUserToEventStart, getEventUserStart, completeEventStart, removeUserFromEventStart} from "../slices/EventSlice";
import { appSyncActivityStart, connectToAppStart, disconnectAppStart, getAppListStart } from "../slices/AppSlice";
import { getAdminDashboardCardStart, getDashboardCardStart, getDashboardGraphStart } from "../slices/DashboardSlice";
import { getAdminDashboardCardHandler, getDashboardCardHandler, getDashboardGraphHandler } from "./handlers/dashboard.handlerSaga";
import { getActivityListHandler, getLastActivityHandler } from "./handlers/activity.handlerSaga";
import { getLastActivityStart, getStravaActivityStart } from "../slices/ActivitySlice";

// Team Slice
import { createTeamStart, deleteTeamStart, getTeamByIdStart, getTeamListStart, updateTeamStart, getTeamPublicListStart, addUsersToTeamStart, getTeamUserStart, getTeamLeaderboardStart, getClubLeaderboardStart } from "../slices/TeamSlice";
import { createTeamHandler, deleteTeamHandler, getTeamByIdHandler, getTeamListHandler, updateTeamHandler,getTeamPublicListHandler, addUsersToTeamHandler, getTeamUserHandler, getTeamLeaderboardHandler } from "./handlers/team.handlerSaga";


export function* watcherSaga() {

    // Club
    yield takeEvery(getClubListStart, getClubListHandler);
    yield takeEvery(getClubPublicListStart, getClubPublicListHandler);
    yield takeEvery(createClubStart, createClubHandler);
    yield takeEvery(getClubByIdStart, getClubByIdHandler);
    yield takeEvery(updateClubStart, updateClubHandler);
    yield takeEvery(deleteClubStart, deleteClubHandler);
    yield takeEvery(getClubLeaderboardStart, getClubLeaderboardHandler);

    // User
    yield takeEvery(getUserListStart, getUserListHandler);
    yield takeEvery(createUserStart, createUserHandler);
    yield takeEvery(getUserByIdStart, getUserByIdHandler);
    yield takeEvery(updateUserStart, updateUserHandler);
    yield takeEvery(deleteUserStart, deleteUserHandler);
    yield takeEvery(getUserEventByUserIdStart, getUserEventHandler);

    // Country
    yield takeEvery(getCountryListStart, getCountryListHandler);

    //Auth
    yield takeEvery(registerStart,registerHandler);
    yield takeEvery(confirmationStart,confirmationHandler);
    yield takeEvery(loginStart, loginHandler);
    yield takeEvery(forgotPasswordStart, forgotPasswordHandler);
    yield takeEvery(updatePasswordStart, updatePasswordHandler);
    yield takeEvery(getUserProfileStart, getProfileHandler)
    yield takeEvery(updateProfileStart, updateProfileHandler)

    // Events
    yield takeEvery(getEventListStart, getEventListHandler);
    yield takeEvery(createEventStart, createEventHandler);
    yield takeEvery(getEventByIdStart, getEventByIdHandler);
    yield takeEvery(updateEventStart, updateEventHandler);
    yield takeEvery(deleteEventStart, deleteEventHandler);
    yield takeEvery(createOrderStart, createOrderHandler);
    yield takeEvery(registerToEventStart, registerEventHandler);
    yield takeEvery(getLeaderboardStart, eventLeaderboardHandler);
    yield takeEvery(getActivityStart, eventEventActivityHandler);
    yield takeEvery(addUserToEventStart, addUserToEventHandler);
    yield takeEvery(getEventUserStart, getEventUsersHandler);
    yield takeEvery(completeEventStart, completeEventHandler);
    yield takeEvery(removeUserFromEventStart, removeUserFromEventHandler);


    // Upload Image
    yield takeEvery(imageUploadStart, imageUploadHandler);

    // Apps
    yield takeEvery(getAppListStart, getAppListHandler);
    yield takeEvery(connectToAppStart, connectToAppHandler);
    yield takeEvery(disconnectAppStart, disconnectAppHandler);
    yield takeEvery(appSyncActivityStart, syncActivityHandler)

    // Dashboard
    yield takeEvery(getDashboardCardStart, getDashboardCardHandler);
    yield takeEvery(getAdminDashboardCardStart, getAdminDashboardCardHandler);
    yield takeEvery(getDashboardGraphStart, getDashboardGraphHandler)
    
    // Activity
    yield takeEvery(getStravaActivityStart, getActivityListHandler)
    yield takeEvery(getLastActivityStart, getLastActivityHandler)

    // Team
    yield takeEvery(getTeamListStart, getTeamListHandler);
    yield takeEvery(getTeamPublicListStart, getTeamPublicListHandler);
    yield takeEvery(createTeamStart, createTeamHandler);
    yield takeEvery(getTeamByIdStart, getTeamByIdHandler);
    yield takeEvery(updateTeamStart, updateTeamHandler);
    yield takeEvery(deleteTeamStart, deleteTeamHandler);
    yield takeEvery(addUsersToTeamStart, addUsersToTeamHandler);
    yield takeEvery(getTeamUserStart, getTeamUserHandler);
    yield takeEvery(getTeamLeaderboardStart, getTeamLeaderboardHandler);
}