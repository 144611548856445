import React, { useState } from 'react';

const FileInput = ({ label, buttonLabel='Choose a file to upload', labelClassName = '', onUpload = null, className = '', isPrview = false, disabled = false }) => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(isPrview);
  const [error, setError] = useState('');

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    if (!selectedFile.type.startsWith('image/')) {
      setError('Please select an image file.');
      return;
    }

    if (selectedFile.size > 5 * 1024 * 1024) { // 5MB limit
      setError('File size should be less than 5MB.');
      return;
    }

    setFile(selectedFile);
    setError('');

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
      onUpload(selectedFile, reader?.result)
    };
    reader.readAsDataURL(selectedFile);
  };

  return (
    <div className={`flex flex-col gap-4 ${className}`}>
      <label className={labelClassName}>{label}</label>
      <div className="relative">
        <input
          disabled={disabled} 
          type="file" 
          onChange={handleFileChange} 
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        />
        <button 
          type="button" 
          className={`block w-full text-sm  py-2 px-4  border-0 font-semibold bg-violet-50 text-violet-700 hover:bg-violet-100 ${ disabled? 'text-gray-500': ''}`}
        >
         { buttonLabel }
        </button>
      </div>
      {error && <p className="text-red-500 mt-2">{error}</p>}
      {preview && isPrview && (
        <div className="mt-4">
          <img 
            src={preview} 
            alt="Selected file preview" 
            className="h-32 w-32 object-cover rounded"
          />
        </div>
      )}
    </div>
  );
};

export default FileInput;

