import React from 'react'; 
import { Button } from 'primereact/button';

const Buttons = ({
    label = '',
    icon = '',
    className = '',
    tooltip = '',
    type='button',
    iconPos = 'left',
    severity = '#',
    size = 'medium',
    onClick = null,
    loading = false,
    rounded = false,
    outline = false,
    raised = false, 
    disabled=false, 
    style = {},
}) => {
  return (
    <Button
      label={label }
      type={type} 
      {...(icon && { icon })}
      // icon={icon } 
      iconPos={iconPos}
      tooltip={tooltip}
      size={size}
      disabled={disabled}
      loading={loading}
      {...(onClick !== null && { onClick })}
      style={style}
      className={className}
      severity={severity}
      rounded={rounded}
      outline={outline}
      raised={raised}
    />      
  )
}

export default Buttons;

        