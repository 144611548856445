import axios from "axios";

// Staging
// const BACKEND_URL = `https://staging-indiarunathon-api.onrender.com/api`;

// prod
const BACKEND_URL = `https://indiarunathon.onrender.com/api`;

// local
// const BACKEND_URL = `http://localhost:4000/api`

const headers = (isMultipart = false) => {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    }
  
    if (isMultipart) {
      header['Content-Type'] = 'multipart/form-data'
    }
    return header
};

const errorHandler = (err) => {
    try {
        const { response } = err;
        const { status } = response;
        if (status === 401) {
            logout();
        }
    } catch (err) {
        throw err;
    }
}
const logout = () => {
    localStorage.clear();
    window.location.reload();
}

const API = {
    CLUB: {
        LIST: ({ params }) => {
            let url = `${BACKEND_URL}/club`;

            // Adding the pagination params
            url += `?page=${ params?.page || 1 }&count=${ params?.count || 10 }`;

            // Adding search params
            if (params?.search) {
                url += `&search=${params?.search}`;
            }

            // Adding isActive params
            if (params?.hasOwnProperty('isActive')) {
                url += `&isActive=${params?.isActive}`;
            }

            // Adding isDeleted params
            if (params?.hasOwnProperty('isDeleted')) {
                url += `&isDeleted=${params?.isDeleted}`;
            }

            return url;
        },
        CREATE: () => `${BACKEND_URL}/club`,
        PUBLIC_LIST: ({params}) => {
            let url = `${BACKEND_URL}/club/list`;

            // Adding the pagination params
            url += `?page=${ params?.page || 1 }&count=${ params?.count || 100 }`;

            // Adding search params
            if (params?.search) {
                url += `&search=${params?.search}`;
            }

            // Adding isActive params
            if (params?.hasOwnProperty('isActive')) {
                url += `&isActive=${params?.isActive}`;
            }

            // Adding isDeleted params
            if (params?.hasOwnProperty('isDeleted')) {
                url += `?isDeleted=${params?.isDeleted}`;
            }

            return url;
        },
        DETAILS: (clubId) => `${BACKEND_URL}/club/${clubId}`
    },
    TEAM: {
        LIST: ({ params }) => {
            let url = `${BACKEND_URL}/team`;

            // Adding the pagination params
            url += `?page=${ params?.page || 1 }&count=${ params?.count || 10 }`;

            // Adding search params
            if (params?.search) {
                url += `&search=${params?.search}`;
            }

            // Adding isActive params
            if (params?.hasOwnProperty('isActive')) {
                url += `&isActive=${params?.isActive}`;
            }

            // Adding isDeleted params
            if (params?.hasOwnProperty('isDeleted')) {
                url += `&isDeleted=${params?.isDeleted}`;
            }

            return url;
        },
        CREATE: () => `${BACKEND_URL}/team`,
        PUBLIC_LIST: ({params}) => {
            let url = `${BACKEND_URL}/team/list`;

            // Adding the pagination params
            url += `?page=${ params?.page || 1 }&count=${ params?.count || 100 }`;

            // Adding search params
            if (params?.search) {
                url += `&search=${params?.search}`;
            }

            // Adding isActive params
            if (params?.hasOwnProperty('isActive')) {
                url += `&isActive=${params?.isActive}`;
            }

            // Adding isDeleted params
            if (params?.hasOwnProperty('isDeleted')) {
                url += `?isDeleted=${params?.isDeleted}`;
            }

            return url;
        },
        DETAILS: (teamId) => `${BACKEND_URL}/team/${teamId}`,
        ADD_USERS_TO_TEAM: (teamId) => `${BACKEND_URL}/team/${teamId}/users`
    },
    COUNTRY: {
        LIST: () => {
            return `${BACKEND_URL}/country`
        }
    },
    USER: {
        LIST: ({ params }) => {
            let url = `${BACKEND_URL}/user`;

            // Adding the pagination params
            url += `?page=${ params?.page || 1 }&count=${ params?.count || 10 }`;

            // Adding search params
            if (params?.search) {
                url += `&search=${params?.search}`;
            }

            // Adding isActive params
            if (params?.hasOwnProperty('isActive')) {
                url += `&isActive=${params?.isActive}`;
            }

            // Adding isDeleted params
            if (params?.hasOwnProperty('isDeleted')) {
                url += `&isDeleted=${params?.isDeleted}`;
            }

            if (params?.hasOwnProperty('club') && params?.club) {
                url += `&club=${params?.club}`
            }

            if (params?.hasOwnProperty('role') && params?.role) {
                url += `&role=${params?.role}`
            }

            if (params?.hasOwnProperty('team') && params?.team) {
                url += `&team=${params?.team}`
            }

            return url;
        },
        DETAILS: (userId) => `${BACKEND_URL}/user/${userId}`,
        CREATE: `${BACKEND_URL}/user`,
        EVENTS: (userId) => `${BACKEND_URL}/user/${userId}/event`
    },
    AUTH: {
        REGISTER : () => `${BACKEND_URL}/auth/register`,
        CONFIRMATION: () => `${BACKEND_URL}/auth/confirmation`, 
        FORGOTPASWORD: () => `${BACKEND_URL}/auth/reset-password`,
        UPDATE_PASSWORD: () => `${BACKEND_URL}/auth/update-password`,
        LOGIN: `${BACKEND_URL}/auth/login`,
        PROFILE: `${BACKEND_URL}/auth/me`
    },
    EVENT: {
        LIST: ({ params }) => {
            let url = `${BACKEND_URL}/event`;

            // Adding the pagination params
            url += `?page=${ params?.page || 1 }&count=${ params?.count || 10 }`;

            // Adding search params
            if (params?.search) {
                url += `&search=${params?.search}`;
            }

            // Adding isActive params
            if (params?.hasOwnProperty('isActive')) {
                url += `&isActive=${params?.isActive}`;
            }

            // Adding isDeleted params
            if (params?.hasOwnProperty('isDeleted')) {
                url += `&isDeleted=${params?.isDeleted}`;
            }

            if (params?.hasOwnProperty('isRegistered')) {
                url+= `&isRegistered=${params?.isRegistered}`
            }

            return url;
        },
        CREATE: `${BACKEND_URL}/event`,
        DETAILS: (eventId) => `${BACKEND_URL}/event/${eventId}`,
        REGISTER: (eventId) => `${BACKEND_URL}/event/${eventId}/register`,
        REMOVE_USER_FROM_EVENT: (eventId, userId) => `${BACKEND_URL}/event/${eventId}/user/${userId}`,
        LEADERBOARD: (eventId) => `${BACKEND_URL}/event/${eventId}/leaderboard`,
        COMPLETE_EVENT: (eventId) => `${BACKEND_URL}/event/${eventId}/finish`,
        TEAM_LEADERBOARD: (eventId) => `${BACKEND_URL}/event/${eventId}/leaderboard/team`,
        CLUB_LEADERBOARD: (eventId, clubId) => `${BACKEND_URL}/event/${eventId}/leaderboard/${clubId}/club`,
        ACTIVITY: (params) => {
            let { eventId, userId, page, count } = params;
            let url = `${BACKEND_URL}/event/${eventId}/leaderboard/${userId}`;

            if (params?.page) {
                url += `page=${page || 1}` 
            }

            if (params?.count) {
                url += `count=${count || 10}`
            }
            
            return url;
        },
        ORDER: {
            CREATE: (eventId) => `${BACKEND_URL}/event/${eventId}/order`
        },
        ADD_USER: (eventId) => `${BACKEND_URL}/event/${eventId}/user`,
        REGISTERED_USER: (params) => {

            let { eventId, search } = params;
            let url = `${BACKEND_URL}/event/${eventId}/users`;
            if (search) {
                url += `?search=${search}`
            }
            
            return url;
         }
    },
    IMAGE_UPLOAD: {
        CREATE: `${BACKEND_URL}/document`
    },
    APP: {
        LIST: ({ params }) => {
            let url = `${BACKEND_URL}/app`;

            // Adding the pagination params
            url += `?page=${ params?.page || 1 }&count=${ params?.count || 10 }`;

            // Adding search params
            if (params?.search) {
                url += `&search=${params?.search}`;
            }

            // Adding isActive params
            if (params?.hasOwnProperty('isActive')) {
                url += `&isActive=${params?.isActive}`;
            }

            // Adding isDeleted params
            if (params?.hasOwnProperty('isDeleted')) {
                url += `&isDeleted=${params?.isDeleted}`;
            }

            return url;
        },
        CREATE: `${BACKEND_URL}/app`,
        CONNECT_TO_APP: (appName) => `${BACKEND_URL}/app/${appName}/connect`,
        DISCONNECT_APP: (appId) => `${BACKEND_URL}/app/${appId}/disconnect`,
        SYNC_ACTIVITY: (appName) => `${BACKEND_URL}/app/${appName}/sync`,
    },
    DASHBOARD: {
        CARD:  `${BACKEND_URL}/dashboard`,
        ADMIN_CARD: `${BACKEND_URL}/dashboard/admin`,
        GRAPH:  `${BACKEND_URL}/dashboard/graph`,
    },
    ACTIVITY: {
        LIST: (params) => {
            let url = `${BACKEND_URL}/actvity`;

            // Adding the pagination params
            url += `?page=${ params?.page || 1 }&count=${ params?.count || 10 }`;

            // Adding search params
            if (params?.search) {
                url += `&search=${params?.search}`;
            }

            if (params?.startDate) {
                url += `&startDate=${params?.startDate}`
            }

            if (params?.endDate) {
                url += `&endDate=${params?.endDate}`
            }

            if (params?.hasOwnProperty('userId')) {
                url += `&user=${params?.userId}`
            }
            return url;
        }
    }
}



// API Calls
export const getClubList = (payload) => {
    try {
        return axios?.get(API?.CLUB?.LIST({ params: payload }), { headers: headers() })
        .catch((err) => {
            errorHandler(err)
        })
    } catch(err) {
        throw err;
    }
}

export const createClub = (payload) => {
    try {
        return axios?.post(API?.CLUB?.CREATE(), payload, { headers: headers() })
        .catch((err) => {
            errorHandler(err)
        })
    } catch(err) {
        throw err;
    }
}

export const getClubById = (payload) => {
    try {
        const { clubId } = payload;
        return axios?.get(API?.CLUB?.DETAILS(clubId), { headers: headers() })
        .catch((err) => {
            errorHandler(err)
        })
    } catch(err) {
        throw err;
    } 
}

export const updateClub = (payload) => {
    const { clubId, ...clubPayload } = payload;
    return axios?.put(API?.CLUB?.DETAILS(clubId), clubPayload, { headers: headers() })
    .catch((err) => {
        errorHandler(err)
    })
}

export const deleteClub = (payload) => {
    const { clubId } = payload;
    return axios?.delete(API?.CLUB?.DETAILS(clubId), { headers: headers() })
    .catch((err) => {
        errorHandler(err)
    })
}

export const getCountryList = () => {
    try {
        return axios?.get(API?.COUNTRY?.LIST(), { headers: headers() })
        .catch((err) => {
            errorHandler(err)
        })
    } catch(err) {
        throw err;
    }
}


// Users
export const getUserList = (payload) => {
    try {
        return axios?.get(API?.USER?.LIST({ params: payload }), { headers: headers() })
        .catch((err) => {
            errorHandler(err)
        })
    } catch(err) {
        throw err;
    }
}

export const createUser = (payload) => {
    try {
        return axios?.post(API?.USER?.CREATE, payload, { headers: headers() })
        .catch((err) => {
            errorHandler(err)
        })
    } catch(err) {
        throw err;
    }
}

export const getUserById = (payload) => {
    try {
        const { userId } = payload;
        return axios?.get(API?.USER?.DETAILS(userId), { headers: headers() })
        .catch((err) => {
            errorHandler(err)
        })
    } catch(err) {
        throw err;
    } 
}

export const updateUser = (payload) => {
    const { userId, ...userPayload } = payload;
    return axios?.put(API?.USER?.DETAILS(userId), userPayload, { headers: headers() })
    .catch((err) => {
        errorHandler(err)
    })
}

export const deleteUser = (payload) => {
    const { userId } = payload;
    return axios?.delete(API?.USER?.DETAILS(userId), { headers: headers() })
    .catch((err) => {
        errorHandler(err)
    })
}

export const getClubPublicList = (payload) => {
    try {
        return axios?.get(API?.CLUB?.PUBLIC_LIST({ params: payload?.params }), { headers: headers() })
    } catch(err){
        throw err;
    }
}

export const register = (payload) => {
    try {
        return axios?.post(API?.AUTH?.REGISTER(), payload)
    } catch(err) {
        throw err;
    }
}

// Users
export const getEventList = (payload) => {
    try {
        return axios?.get(API?.EVENT?.LIST({ params: payload }), { headers: headers() })
        .catch((err) => {
            errorHandler(err)
        })
    } catch(err) {
        throw err;
    }
}

export const createEvent = (payload) => {
    try {
        return axios?.post(API?.EVENT?.CREATE, payload, { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}

export const getEventById = (payload) => {
    try {
        const { eventId } = payload;
        return axios?.get(API?.EVENT?.DETAILS(eventId), { headers: headers() })
        .catch((err) => {
            errorHandler(err)
        })
    } catch(err) {
        throw err;
    } 
}

export const updateEvent = (payload) => {
    const { eventId, ...eventPayload } = payload;
    return axios?.put(API?.EVENT?.DETAILS(eventId), eventPayload, { headers: headers() })
    .catch((err) => {
        errorHandler(err)
    })
}

export const deleteEvent = (payload) => {
    const { eventId } = payload;
    return axios?.delete(API?.EVENT?.DETAILS(eventId), { headers: headers() })
    .catch((err) => {
        errorHandler(err)
    })
}

// Upload Image
export const uploadImage = (file) => {
    const formData = new FormData();
    formData.append('document', file);
    try {
      return axios.post(API.IMAGE_UPLOAD.CREATE, formData, { headers: headers(true) }).catch((err) => {
        errorHandler(err)
      })
    } catch (err) {
      throw err;
    }
}

export const confirmation = (payload) => {
    try {
        return axios?.post(API?.AUTH?.CONFIRMATION(), {},
            { headers: { Authorization: `Bearer ${payload}`,
            "Content-Type": "application/json"} } 
        )
    } catch(err) {
        throw err;
    }
}

export const login = (payload) => {
    try {
        return axios.post(API?.AUTH?.LOGIN, payload).catch((err) => {
            throw err;
        });
    } catch(err) {
        throw err;
    }
}

export const listApps = (payload) => {
    try {
        return axios?.get(API?.APP?.LIST({ params: payload }), { headers: headers() })
        .catch((err) => {
            errorHandler(err)
            throw err;
        })
    } catch(err) {
        throw err;
    }
}

export const connectApps = (payload) => {
    try {
        return axios.post(API?.APP?.CONNECT_TO_APP(payload?.appName), { code: payload?.authCode }, { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}

export const disconnectApp = (payload) => {
    try {
        return axios.put(API?.APP?.DISCONNECT_APP(payload?.appId), {}, { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}

export const forgotPassword = (payload) => {
    try {
        return axios?.post(API?.AUTH?.FORGOTPASWORD(), payload)
    } catch(err) {
        throw err;
    }
}

export const updatePassword = (payload) => {
    try {
        const { token, password } = payload;
        return axios?.post(API?.AUTH?.UPDATE_PASSWORD(), { password },
        { headers: { Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"} } 
    )
    } catch(err) {
        throw err;
    }
}

export const createOrder = (payload) => {
    try {
        const { eventId } = payload;
        return axios.post(API?.EVENT?.ORDER?.CREATE(eventId), {}, { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}

export const registerToEvent = (payload) => {
    try {
        const { eventId } = payload;
        return axios?.post(API?.EVENT?.REGISTER(eventId), { paymentId: payload?.paymentId }, { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}

export const getEventLeaderboard = (payload) => {
    try {
        const { eventId } = payload;
        return axios?.get(API?.EVENT?.LEADERBOARD(eventId), { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}

export const getEventActivity = (payload) => {
    try {
        return axios?.get(API?.EVENT?.ACTIVITY(payload), { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}


export const getDashboardCard = () => {
    try {
        return axios?.get(API?.DASHBOARD?.CARD, { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}

export const getAdminDashboardCard = () => {
    try {
        return axios?.get(API?.DASHBOARD?.ADMIN_CARD, { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}


export const getActivities = (payload) => {
    try {
        return axios?.get(API?.ACTIVITY?.LIST(payload), { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}


export const getUserEventByUserId = (payload) => {
    try {
        const { userId } = payload;
        return axios?.get(API?.USER?.EVENTS(userId), { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}

export const syncActivity = (payload) => {
    try {
        const postPayload = { user: payload?.user, startDate: payload?.startDate, endDate: payload?.endDate };
        return axios?.post(API?.APP?.SYNC_ACTIVITY(payload?.appName), postPayload, { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}

export const deleteActivity = (payload) => {
    try {
        const postPayload = { user: payload?.user, startDate: payload?.startDate, endDate: payload?.endDate };
        return axios?.post(API?.APP?.SYNC_ACTIVITY(payload?.appName), postPayload, { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}

export const addUserToEvent = (payload) => {
    try {
        const { eventId, userId } = payload;
        return axios?.post(API?.EVENT?.ADD_USER(eventId), { user: userId }, { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}

export const getEventUsers = (payload) => {
    try {
        return axios?.get(API?.EVENT?.REGISTERED_USER(payload), { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}


export const getDashboardGraph = (payload) => {
    try {
        return axios?.post(API?.DASHBOARD?.GRAPH, payload, { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}


export const getProfile = () => {
    try {
        return axios?.get(API?.AUTH?.PROFILE, { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}

export const updateProfile = (payload) => {
    try {
        return axios?.put(API?.AUTH?.PROFILE, payload, { headers: headers() })
        .catch((err) => {
            errorHandler(err);
            throw err;
        })
    } catch(err) {
        throw err;
    }
}


export const getTeamList = (payload) => {
    try {
        return axios?.get(API?.TEAM?.LIST({ params: payload }), { headers: headers() })
        .catch((err) => {
            errorHandler(err)
        })
    } catch(err) {
        throw err;
    }
}

export const createTeam = (payload) => {
    try {
        return axios?.post(API?.TEAM?.CREATE(), payload, { headers: headers() })
        .catch((err) => {
            errorHandler(err)
        })
    } catch(err) {
        throw err;
    }
}

export const getTeamById = (payload) => {
    try {
        const { teamId } = payload;
        return axios?.get(API?.TEAM?.DETAILS(teamId), { headers: headers() })
        .catch((err) => {
            errorHandler(err)
        })
    } catch(err) {
        throw err;
    } 
}

export const updateTeam = (payload) => {
    const { teamId, ...clubPayload } = payload;
    return axios?.put(API?.TEAM?.DETAILS(teamId), clubPayload, { headers: headers() })
    .catch((err) => {
        errorHandler(err)
    })
}

export const deleteTeam = (payload) => {
    const { teamId } = payload;
    return axios?.delete(API?.TEAM?.DETAILS(teamId), { headers: headers() })
    .catch((err) => {
        errorHandler(err)
    })
}

export const getTeamPublicList = (payload) => {
    try {
        return axios?.get(API?.TEAM?.PUBLIC_LIST({ params: payload?.params }), { headers: headers() })
    } catch(err){
        throw err;
    }
}

export const addUsersToTeam = (payload) => {
    try {
        const teamId = payload?.teamId
        if (teamId) {
            delete payload?.teamId
        }
        return axios?.post(API?.TEAM?.ADD_USERS_TO_TEAM(teamId),  payload,  { headers: headers() })?.catch((err) => { errorHandler(err); throw err; })
    } catch(err){
        throw err;
    }
}

export const getTeamUser = (payload) => {
    try {
        const teamId = payload?.teamId
        if (!teamId) {
            return;
        }
        return axios?.get(API?.TEAM?.ADD_USERS_TO_TEAM(teamId),  { headers: headers() })?.catch((err) => { errorHandler(err); throw err; })
    } catch(err){
        throw err;
    }
}

export const getTeamleaderboard = (payload) => {
    try {
        const eventId = payload?.eventId;
        return axios?.get(API?.EVENT?.TEAM_LEADERBOARD(eventId), { headers: headers() })?.catch((err) => { errorHandler(err); throw err; })
    } catch(err) {
        throw err;
    }
}


export const getClubLeaderboard = (payload) => {
    try {
        const { eventId, clubId } = payload || { eventId: '', clubId: ''}
        if (!eventId || !clubId) {
            return;
        }
        return axios?.get(API?.EVENT?.CLUB_LEADERBOARD(eventId, clubId), { headers: headers() })?.catch((err) => { errorHandler(err); throw err; })
    } catch(err) {
        throw err;
    }
}

export const completeEvent = (payload) => {
    try {
        const { eventId } = payload || { eventId: '' }
        if (!eventId) {
            return;
        }
        const updatePayload = {};
        if (payload?.user) {
            updatePayload['user'] = payload?.user;
        }
        return axios.put(API?.EVENT?.COMPLETE_EVENT(eventId),  updatePayload, { headers: headers() }).catch((err) => { errorHandler(err); throw err; })
    } catch(err) {
        throw err;
    }
}

export const removeUserFromEvent = (payload) => {
    try {
        const { eventId, userId } = payload || { eventId: '', userId: '' };
        if (!eventId || !userId) {
            return;
        }
        return axios?.delete(API?.EVENT?.REMOVE_USER_FROM_EVENT(eventId, userId), { headers: headers() }).catch((err) => {
            errorHandler(err);
        })
    } catch(err) {
        throw err;
    }
}