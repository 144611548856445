import React, { useEffect } from 'react'

// Common Components
import Button from '../../../components/Button'
import { ICONS } from '../../../constant/types'

// Store
import { useDispatch, useSelector } from 'react-redux'
import { deleteClubStart } from '../../../store/slices/ClubSlice'
import Loader from '../../../components/Loader'

const DeleteClub = ({ club, onClose }) => {

    const dispatch = useDispatch();
    const clubs = useSelector((state) => state?.club);

    const onDeleteHandler = () => {
        try {
            dispatch(deleteClubStart({ clubId: club?.id }))
            onClose();
        } catch(err) {
            throw err;
        }
    }

  return (
    <>
        <Loader isVisible={clubs?.isLoading} />
        <div className='flex flex-col justify-center items-center'>
            <p className='font-bold text-xl'>Are you sure ?</p>
            <p>Do you want to delete this <span className='font-semibold'>{club?.name}</span> club</p>
            <div className='flex justify-between gap-4 my-8'>
                <Button 
                    label='Delete' 
                    className='bg-red-700 border-none' 
                    icon={ICONS?.DELETE('h-6 w-6 mx-1')}
                    onClick={() => { onDeleteHandler() }} 
                />
                <Button 
                    label='Cancel' 
                    className='border-none' 
                    icon={ICONS?.CANCEL('h-6 w-6 mx-1')} 
                    onClick={() => { onClose() }}
                />
            </div>
        </div>
    </>
    
  )
}

export default DeleteClub