import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    lastActivity: null,
    message: null,
    error: null
}

const ActivitySlice = createSlice({
    name: 'Activity',
    initialState,
    reducers: {
        getStravaActivityStart: (state) => {
            state.data = [];
            state.message = null;
            state.error = null;
            state.isLoading = true;
        },
        getActivitySuccess: (state, action) => {
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        getActivityFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },

        // Get last activiy
        getLastActivityStart: (state) => {
            state.isLoading = true;
        },
        getLastActivitySuccess: (state, action) => {
            state.lastActivity = action?.payload?.data?.activity[0] || {};
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        getLastActivityFailed: (state, action) => {
            state.error = action?.payload;
            state.lastActivity = null;
            state.isLoading = false;
        }
    }
});

export const {
    getStravaActivityStart,
    getActivitySuccess,
    getActivityFailed,
    getLastActivityStart,
    getLastActivitySuccess,
    getLastActivityFailed
} = ActivitySlice?.actions;
export default ActivitySlice?.reducer;