import { put, call } from 'redux-saga/effects';

// Reducers
import { getAppListSuccess, getAppListFailed, connectToAppSuccess, connectToAppFailed, disconnectAppSuccess, disconnectAppFailed, getAppListStart, appSyncActivityFailed, appSyncActivitySuccess } from '../../slices/AppSlice';

// Services
import { connectApps, disconnectApp, listApps, syncActivity } from '../../services/requests';
import { getStravaActivityStart } from '../../slices/ActivitySlice';

// Handlers
export function* getAppListHandler(actions) {
    try {
        const response = yield call(listApps, actions?.payload);
        yield put(getAppListSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getAppListFailed(errResponse))
    }
}


export function* connectToAppHandler(actions) {
    try {
        const response = yield call(connectApps, actions?.payload);
        yield put(connectToAppSuccess(response?.data));
        yield put(getAppListStart())
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(connectToAppFailed(errResponse))
    }
}

export function* disconnectAppHandler(actions) {
    try {
        const response = yield call(disconnectApp, actions?.payload);
        yield put(disconnectAppSuccess(response?.data));
        yield put(getAppListStart())
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(disconnectAppFailed(errResponse))
    }
}

export function* syncActivityHandler(actions) {
    try {
        const response = yield call(syncActivity, actions?.payload);
        yield put(appSyncActivitySuccess(response?.data));
        yield put(getStravaActivityStart({ userId: actions?.payload?.user }))
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(appSyncActivityFailed(errResponse))
    }
}