import React, { useEffect } from 'react'

// Common Components
import Button from '../../../components/Button'
import { ICONS } from '../../../constant/types'
import Loader from '../../../components/Loader'

// Store
import { useDispatch, useSelector } from 'react-redux'
import { deleteUserStart } from '../../../store/slices/UserSlice'


const DeleteUser = ({ user, onClose }) => {

    const dispatch = useDispatch();
    const users = useSelector((state) => state?.user);

    const onDeleteHandler = () => {
        try {
            dispatch(deleteUserStart({ userId: user?.id, role: user?.role }))
            onClose();
        } catch(err) {
            throw err;
        }
    }

  return (
    <>
        <Loader isVisible={users?.isLoading} />
        <div className='flex flex-col justify-center items-center'>
            <p className='font-bold text-xl'>Are you sure ?</p>
            <p>Do you want to delete this <span className='font-semibold'>{user?.name}</span> user</p>
            <div className='flex justify-between gap-4 my-8'>
                <Button 
                    label='Delete' 
                    className='bg-red-700 border-none' 
                    icon={ICONS?.DELETE('h-6 w-6 mx-1')}
                    onClick={() => { onDeleteHandler() }} 
                />
                <Button 
                    label='Cancel' 
                    className='border-none' 
                    icon={ICONS?.CANCEL('h-6 w-6 mx-1')} 
                    onClick={() => { onClose() }}
                />
            </div>
        </div>
    </>
    
  )
}

export default DeleteUser