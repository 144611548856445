import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PLEDGE_PDF } from '../../../constant/types';

function Pledges() {
    const navigate = useNavigate();
  return (
    <div className='font-sans'>
        <section
            className="relative h-screen flex items-center justify-center bg-cover bg-center bg-primary"
        >
            <div className="absolute inset-0 opacity-50"></div>
            <div className="container relative text-center text-white px-6 md:px-12">
            <h1 className="text-5xl md:text-6xl font-bold leading-tight font-montserrat">
            Take the Pledges - Commit to a Healthier Future
            </h1>
            {/* <p className="mt-4 text-xl md:text-2xl text-justify">
                At India Runathon, we believe in not only challenging ourselves physically but also making positive lifestyle choices that contribute to a healthier, happier society. By taking a pledge, you’re making a commitment to better yourself and inspire others in our community. Choose a pledge that resonates with you, download the PDF, and take the first step towards a healthier life
            </p> */}
            <div
                className="mt-8 inline-block bg-info  hover:bg-black text-white hover:text-white hover:cursor-pointer font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300"
            >
                Take the Pledges
          </div>
            </div>
        </section>

        <section className="py-4 md:py-4 bg-gray-50">
            <div className="container mx-auto text-center px-6 md:px-4">
                <p className='text-justify'>
                    At India Runathon, we believe in not only challenging ourselves physically but also making positive lifestyle choices that contribute to a healthier, happier society. By taking a pledge, you’re making a commitment to better yourself and inspire others in our community. Choose a pledge that resonates with you, download the PDF, and take the first step towards a healthier life
                </p>
            </div>
        </section>

        <section className="py-8 md:py-8 bg-gray-50 text-center">
            <div className="container mx-auto px-6 md:px-12">
                <span className="text-2xl md:text-4xl font-bold text-primary mb-12">How to Take the Pledge</span>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 my-12">
                    <div className="p-8 bg-white rounded-3xl shadow-lg">
                        <div className='flex justify-center'>
                            <div className='flex justify-start gap-4 items-center'><span className="text-xl font-bold text-center">1. CHOOSE YOUR PLEDGE</span></div>
                        </div>
                        <p className="text-black text-sm text-justify">
                        Select the pledge that matters most to you.
                        </p>
                    </div>
                    <div className="p-8 bg-white rounded-3xl shadow-lg">
                        <div className='flex justify-center'>
                            <div className='flex justify-start gap-4 items-center'><span className="text-xl font-bold text-center">2. DOWNLOAD PDF</span></div>
                        </div>
                        <p className="text-black text-sm text-justify">
                            Click on the link to download your chosen pledge.
                        </p>
                    </div>
                    <div className="p-8 bg-white rounded-3xl shadow-lg">
                        <div className='flex justify-center'>
                            <div className='flex justify-start gap-4 items-center'><span className="text-xl font-bold text-center">3. SIGN AND SHARE</span></div>
                        </div>
                        <p className="text-black text-sm text-justify">
                            Sign your pledge and share it with your friends, family and the India Runathon community. Encourage others to take the pledge with you!
                        </p>
                    </div>
                    <div className="p-8 bg-white rounded-3xl shadow-lg">
                        <div className='flex justify-center'>
                            <div className='flex justify-start gap-4 items-center'><span className="text-xl font-bold text-center">4. LIVE YOUR COMMITMENT</span></div>
                        </div>
                        <p className="text-black text-sm text-justify">
                            Make a conscious effort to live by your pledge every day and inspire others to do the same.
                        </p>
                    </div>
                </div>
            </div>
        </section>


        <section className="py-8 md:py-8 bg-gray-50 text-center">
                <div className="container mx-auto px-6 md:px-12">
                    <span className="text-2xl md:text-4xl font-bold text-primary mb-12">Available Pledges</span>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 my-12">
                        <div className="p-8 bg-white rounded-3xl shadow-lg">
                            <div className='flex justify-center'>
                                <div className='flex justify-start gap-4 items-center'><span className="text-xl font-bold">BLOOD DONATION PLEDGE</span></div>
                            </div>
                            <p className="text-black text-sm text-justify">
                                Pledge to donate blood regularly and save lives. Your commitment can make a life-changing difference to those in need.
                            </p>
                            <a href={PLEDGE_PDF?.BLOOD_DONATION} download={'Blood_Donation.pdf'} target='_blank' className='no-underline'>
                                <div className='bg-info text-white rounded-full text-sm text-center shadow-2xl hover:cursor-pointer p-2 font-semibold'>
                                    <span className='pi pi-sort-down-fill mr-2'></span>
                                    Download Blood Donation Pledge PDF
                                </div>
                            </a>
                        </div>
                        <div className="p-8 bg-white rounded-3xl shadow-lg">
                            <div className='flex justify-center'>
                                <div className='flex justify-start gap-4 items-center'>
                                    <span className="text-xl font-bold">SAY NO TO TOBACCO</span>
                                </div>
                            </div>
                            <p className="text-black text-sm text-justify">
                                Commit to a tobacco-free life and encourage others to quit as well. Let’s work together to create a healthier, smoke-free environment.
                            </p>
                            <a href={PLEDGE_PDF?.SAY_NO_TO_DOBACK} download={'Say_No_to_Tobacco.pdf'} target='_blank' className='no-underline'>
                                <div className='bg-info text-white rounded-full text-sm text-center shadow-2xl hover:cursor-pointer p-2 font-semibold'>
                                    <span className='pi pi-sort-down-fill mr-2'></span>
                                    Download Say No to Tobacco Pledge PDF
                                </div>
                            </a>
                        </div>
                        <div className="p-8 bg-white rounded-3xl shadow-lg">
                            <div className='flex justify-center'>
                                <div className='flex justify-start gap-4 items-center'><span className="text-xl font-bold">SAY NO TO DRUG</span></div>
                            </div>
                            <p className="text-black text-sm text-justify">
                                Take a stand against drug use. Pledge to stay drug-free and support those who need help to break free from addiction.
                            </p>
                            <a href={PLEDGE_PDF?.BLOOD_DONATION} download={'Say_No_to_Drug.pdf'} target='_blank' className='no-underline'>
                                <div className='bg-info lg:mt-8 text-white rounded-full text-sm text-center shadow-2xl hover:cursor-pointer p-2 font-semibold'>
                                    <span className='pi pi-sort-down-fill mr-2'></span>
                                    Download Say No To Drug Pledge PDF
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
        </section>

        <section className="py-8 md:py-8 bg-gray-50 text-center">
                <div className="container mx-auto px-6 md:px-12">
                    <span className="text-2xl md:text-4xl font-bold text-primary mb-12">Join Us in Making a Difference</span>
                    <p>Your pledge is more than just words; it’s a commitment to yourself and your community. Together, we can create a healthier, stronger and more united India.
                    </p>
                </div>
        </section>
        

      
        <footer className="bg-gray-900 text-gray-400 py-6 mt-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
              <p className="text-sm sm:text-base">
              © {new Date().getFullYear()} Indiarunathon. All Rights Reserved.
              </p>
          </div>
        </div>
      </footer>

    </div>
  )
}

export default Pledges;