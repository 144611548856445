import React, { useEffect, useState, useRef } from 'react'

// Components
import Mention from '../../../components/Mention';
import Toast from '../../../components/Toast';
import Button from '../../../components/Button';

import { useDispatch, useSelector } from 'react-redux'
import { getUserListStart } from '../../../store/slices/UserSlice';

// ICONS
import { ICONS } from '../../../constant/types';
import { TABLE, toastMessageBuilder } from '../../../utils/utils';
import { addUsersToTeamStart, getTeamUserStart } from '../../../store/slices/TeamSlice';
import Loader from '../../../components/Loader';

const AddUserToTeam = ({ team, onClose }) => {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state?.user);
    const teams = useSelector((state) => state?.team);
    const [value, setValue] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [existingUser, setExistingUser] = useState([]);
    const [users, setUsers] = useState([]);
    const [isUserAdded, setIsUserAdded] = useState(false);
    const [userParams, setUserParams] = useState({
        page: 1,
        count: 50,
        search: '',
        isActive: true,
        isDeleted: false,
    })
    const toast = useRef(null);
    useEffect(() => {
        if (userParams?.search) {
          const delayDebounceFn = setTimeout(() => {
              dispatch(getUserListStart(userParams));
          }, 300)
          return () => clearTimeout(delayDebounceFn);
        } else {
          fetchUserList(userParams)
        }
    }, [dispatch, userParams])

    useEffect(() => {
        fetchTeamUser();
    }, [dispatch])

    useEffect(() => {
        if (teams?.users?.users?.length) {
            setExistingUser(TABLE?.USER?.FORMATED(teams?.users?.users))
        }
    }, [teams?.users?.users])

    useEffect(() => {
        if (isUserAdded && teams?.error === null && teams?.message) {
            toast?.current?.show(toastMessageBuilder({
                title: 'Team',
                message: 'Users Added into Teams'
            }))
            onClose();
        }
    }, [teams?.error, teams?.message])

    const fetchUserList = async (params) => {
        try {
          dispatch(getUserListStart(params))
        } catch(err) {
          throw err;
        }
    }

    const fetchTeamUser = () => {
        try {
            if (!team?.id) return;
            dispatch(getTeamUserStart({ teamId: team?.id }))
        } catch(err) {
            throw err;
        }
    }

    const onUserSearchHandler = (e) => {
        try {
          setUserParams((params) => ({
            ...params,
            search: e?.query
          }))
        } catch(err) {
          throw err;
        }
      }
    
      const itemTemplate = (user) => {
        return (
            <div className="flex flex-row">
                <img alt={user.name} src={user?.profilePicture} className='w-12 h-12 rounded' />
                <span className="flex flex-col ml-2">
                    <span className='text-sm'>{user.name}</span>
                    <span className='text-xs text-gray-400'>@{user.email}</span>
                </span>
            </div>
        );
      }
    
      const onSelectUser = (user) => {
        try {
            const users = [...selectedUsers];
            const isUserExist = users?.findIndex((existedUser) => existedUser?.id === user?.id);
            if (isUserExist !== -1) {
                setValue('');
                toast?.current?.show(toastMessageBuilder({
                    title: 'Users',
                    message: `${user?.name} already added into team`,
                    severity:'info'
                }))
                return;
            }
            if (existingUser?.length) {
                const isUserAlredyExisted = existingUser?.findIndex((existedUser) => existedUser?.id === user?.id);
                if (isUserAlredyExisted !== -1) {
                    setValue('');
                    toast?.current?.show(toastMessageBuilder({
                        title: 'Users',
                        message: `${user?.name} already added into team`,
                        severity:'info'
                    }))
                    return;
                }
            }
            users?.push(user);
            setSelectedUsers(users);
            setValue('');
        } catch(err) {
            throw err;
        }
    }

    const removeSelectedUser = (userId) => {
        try {
          if (!userId) {
            return;
          }
          const updatedUsers = selectedUsers?.filter((user) => user?.id !== userId);
          setSelectedUsers([...updatedUsers]);
        } catch(err) {
          throw err;
        }
    }

    const onSubmitHandler = () => {
        try {
            const addUserPayload = {
                users: selectedUsers?.map((user) => user?.id),
                teamId: team?.id
            }
            dispatch(addUsersToTeamStart(addUserPayload))
            setIsUserAdded(true);
        } catch(err) {
            throw err;
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <Loader isVisible={teams?.isLoading} />
            <div className='flex flex-col gap-4'>

                {existingUser?.length ? <div>
                    <p className='font-semibold '>Users: </p>
                    <div className='grid grid-cols-4 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-8'>
                    {
                        existingUser?.map((user) => (
                        <div 
                            className='p-2 border-1 border-solid shadow-md rounded-xl bg-gray-100 cursor-not-allowed'
                        >
                            <div className="flex flex-row">
                            <img alt={user.name} src={user?.profilePicture} className='w-12 h-12 rounded-full' />
                            <div className="flex flex-col ml-2">
                                <span className='text-xs'>{user.name}</span>
                            </div>
                            </div>
                            {/* <div className='flex justify-end'>
                                <span onClick={() => {removeSelectedUser(user?.id)}}>{ ICONS?.DELETE('text-red-500 cursor-pointer h-5 w-5 font-semibold')}</span>
                            </div> */}
                        </div>
                        ))
                    }
                    </div>
                </div> : <></> }

                {selectedUsers?.length ? <div>
                    <p className='font-semibold'>Newly Added Users</p>
                    <div className='grid grid-cols-4 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-8'>
                    {
                        selectedUsers?.map((user) => (
                        <div 
                            className='p-2 border-1 border-solid shadow-md rounded-xl'
                        >
                            <div className="flex flex-row">
                            <img alt={user.name} src={user?.profilePicture} className='w-12 h-12 rounded-full' />
                            <div className="flex flex-col ml-2">
                                <span className='text-xs'>{user.name}</span>
                            </div>
                            </div>
                            <div className='flex justify-end'>
                            <span onClick={() => {removeSelectedUser(user?.id)}}>{ ICONS?.DELETE('text-red-500 cursor-pointer h-5 w-5 font-semibold')}</span>
                            </div>
                        </div>
                        ))
                    }
                    </div>
                </div> : <></> }
                <div className='w-full mt-16'>
                    <Mention 
                        label='Select Users' 
                        labelClassName='font-semibold'
                        className={'w-full'} 
                        value={value}
                        onChange={(e) => { setValue(e?.target?.value )}}
                        placeholder='Enter @ to choose user' 
                        rows={10}
                        suggestions={TABLE?.USER?.FORMATED(data?.users)}
                        onSearch={(e) => { onUserSearchHandler(e) }}
                        itemTemplate={itemTemplate}
                        field={'name'}
                        onSelect={(user) => {onSelectUser(user)}}
                    />
                </div>
                <div className='flex justify-end'>
                    <Button 
                        label={`Add Users to ${team?.name || ''}`}
                        size={'small'}
                        outline={true}
                        onClick={() => { onSubmitHandler() }}
                        className={'outline-none border-none bg-black h-12'}
                    />
                </div>
            </div>
            
        </>
    )
}

export default AddUserToTeam