import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    data: null,
    app: null,
    message: null,
    error: null
}

const AppSlice = createSlice({
    name: 'Apps',
    initialState,
    reducers: {
        getAppListStart: (state) => {
            state.isLoading = true;
        },
        getAppListSuccess: (state, action) => {
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false
        },
        getAppListFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false
        },

        // Connect To APP
        connectToAppStart: (state) => {
            state.isLoading = true;
        },
        connectToAppSuccess: (state, action) => {
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        connectToAppFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },

        // Disconnect App
        disconnectAppStart: (state) => {
            state.isLoading = true;
        },
        disconnectAppSuccess: (state, action) => {
            state.data = action?.payload?.data;
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        disconnectAppFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        },

        appSyncActivityStart: (state) => {
            state.isLoading = true;
            state.message = null;
            state.error = null;
        },
        appSyncActivitySuccess: (state, action) => {
            state.message = action?.payload?.message;
            state.isLoading = false;
        },
        appSyncActivityFailed: (state, action) => {
            state.error = action?.payload;
            state.isLoading = false;
        }
    }
});

export const {
    getAppListStart,
    getAppListSuccess,
    getAppListFailed,
    connectToAppStart,
    connectToAppSuccess,
    connectToAppFailed,
    disconnectAppStart,
    disconnectAppSuccess,
    disconnectAppFailed,
    appSyncActivityStart,
    appSyncActivitySuccess,
    appSyncActivityFailed
} = AppSlice?.actions;
export default AppSlice?.reducer;