import { put, call } from 'redux-saga/effects';

// Reducers
import { getDashboardCardSuccess, getDashboardCardFailed, getAdminDashboardCardFailed, getAdminDashboardCardSuccess, getDashboardGraphSuccess, getDashboardGraphFailed } from '../../slices/DashboardSlice';

// Services
import { getAdminDashboardCard, getDashboardCard, getDashboardGraph } from '../../services/requests';


export function* getDashboardCardHandler(actions) {
    try {
        const response = yield call(getDashboardCard, actions?.payload);
        yield put(getDashboardCardSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getDashboardCardFailed(errResponse))
    }
}


export function* getAdminDashboardCardHandler(actions) {
    try {
        const response = yield call(getAdminDashboardCard, actions?.payload);
        yield put(getAdminDashboardCardSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getAdminDashboardCardFailed(errResponse))
    }
}


export function* getDashboardGraphHandler(actions) {
    try {
        const response = yield call(getDashboardGraph, actions?.payload);
        yield put(getDashboardGraphSuccess(response?.data));
    } catch(err) {
        const errResponse = err?.response?.data?.message;
        yield put(getDashboardGraphFailed(errResponse))
    }
}